// assets
import { IconKey,Icon123 } from '@tabler/icons';

// constant
const icons = {
    IconKey,
    Icon123
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'monitoring_supervision_pages',
    title: 'Services',
    caption: 'Les Services',
    type: 'group',
    children: [
        // {
        //     id: 'supervision_servers',
        //     title: 'Servers',
        //     type: 'collapse',
        //     icon: icons.Icon123,

        //     children: [
        //         {
        //             id: 'supervision_servers',
        //             title: 'Servers',
        //             type: 'item',
        //             url: '/supervision/servers-page',
        //             external: false,
        //             target: false,
        //             breadcrumbs: true
        //         }
        //     ]
        // },
        {
            id: 'supervision_shellScripts',
            title: 'Scripts Shell',
            type: 'collapse',
            icon: icons.Icon123,

            children: [
                {
                    id: 'supervision_shellScripts',
                    title: 'Scripts Shell',
                    type: 'item',
                    url: '/supervision/ShellScripts-page',
                    external: false,
                    target: false,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'supervision_sqlCmds',
            title: 'SQL Commandes',
            type: 'collapse',
            icon: icons.Icon123,

            children: [
                {
                    id: 'supervision_sqlCmds',
                    title: 'SQL Commandes',
                    type: 'item',
                    url: '/supervision/sqlCmds-page',
                    external: false,
                    target: false,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'supervision_urlInvocations',
            title: 'Invocations URL',
            type: 'collapse',
            icon: icons.Icon123,

            children: [
                {
                    id: 'supervision_urlInvocations',
                    title: 'Invocations URL',
                    type: 'item',
                    url: '/supervision/UrlInvocations-page',
                    external: false,
                    target: false,
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default pages;
