import jwtDecode from "jwt-decode";


const getCurrentUser = () =>{
    let token = localStorage.getItem('user');
    if (token) {
        try {
            token = token.replace('Bearer ',token);
            const decodedToken = jwtDecode(token);
            console.log(decodedToken);
            let result = {
                "userId":decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
                "role":decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
                "email":decodedToken["sub"],
                "name":decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous"],
                "dateofbirth":decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dateofbirth"],
                "phone":decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone"],
                "city":decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country"],
            };
            return result;
            // Use the decoded token as needed
        } catch (error) {
            console.error('Error decoding JWT:', error);
        }
    }

    return {};
}
const getPwdsServers = () =>{
    let treeServer = localStorage.getItem('treeServer');
    if (treeServer) {
        try {
            return JSON.parse(treeServer);
        } catch (error) {
            console.error('Error treeServer:', error);
        }
    }

    return [];
}
let config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    domaineName: "https://mts-api.timesheet.tn",
    // domaineName: "https://localhost:44398",
    // domaineName: "https://localhost:8082",
    decodeToken:getCurrentUser(),
    treeServerSepcified:getPwdsServers(),

    jwt: {
        // secret: '706dc3d4-11aa-4b44-b519-bbf6be768564',
        secret: 'HS256',
        timeout: '1 days'
    }
};
// if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
//     config = {
//         // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
//         // like '/berry-material-react/react/default'
//         basename: '',
//         defaultPath: '/dashboard/default',
//         fontFamily: `'Roboto', sans-serif`,
//         borderRadius: 12,
//         domaineName: "https://localhost:44398",
//         jwt: {
//             secret: 'SECRET-KEY',
//             timeout: '1 days'
//         }
//     };
// }
export default config;
