import axios from 'axios';
import config from '../config';
const API_URL = config.domaineName+'/api/Users/';

const register = (nom, city, email, dateOfBirth, password, confirmPassword, phoneNumber) => {
    const model = {
        nom: nom,
        city: city,
        email: email,
        dateOfBirth: dateOfBirth,
        password: password,
        confirmPassword: confirmPassword,
        phoneNumber: phoneNumber
    };
    return axios.post(API_URL + 'register', model).then(
        (response) => {
            if (response.status == 200) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
        }
    );
};

const login = (email, password, rememberMe) => {
    const model = {
        email: email,
        password: password,
        rememberMe: rememberMe
    };
    return axios.post(API_URL + 'signin', model).then(
        (response) => {
            if (response.status == 200) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
        }
    );
};

const logout = () => {
    localStorage.removeItem('user');
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'));
};

const AuthServiceUser = {
    register,
    login,
    logout,
    getCurrentUser
};

export default AuthServiceUser;
