import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import DynamiqueMenuButton from 'ui-component/Tabs/DynamiqueMenuButton';
import InstanceService from './InstanceService';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import RestoreDbService from 'services/RestoreDbs.service';
export default function TransferDataBase(props) {

    const [SelectValueTranferDatabase, setSelectValueTranferDatabase] = useState("");
    const [OpenMessageSuccess, setOpenMessageSuccess] = useState(false);
    useEffect(() => {
    }, []);


    const SubmittransferDatabase = async (event) => {
        props.OpenLoading(true);
        await RestoreDbService.ExcuteRequstSQlTranferDatabase(SelectValueTranferDatabase, props.treeChecked).then(
            (succ) => {
                
                console.log(succ);
                // setOpenLoading(false);
                props.OpenLoading(false);
                setOpenMessageSuccess(true);
                props.BtnActualiser();
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        );
    };

    return (
        <React.Fragment  >


            <DialogTitle>Importer les bases de données à un serveur différent</DialogTitle>
            <DialogContent>
                <DialogContentText>

                    <IconButton
                        aria-label="close"
                        onClick={props.closeDialogue}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <DisabledByDefaultIcon />
                    </IconButton>
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                            <InputLabel id="select-label-tranfer">Importer les bases de données à un serveur</InputLabel>
                            <Select
                                labelId={"select-label-tranfer"}
                                value={SelectValueTranferDatabase}
                                variant='outlined'
                                required
                                onChange={(event) => {
                                    setSelectValueTranferDatabase(event.target.value.toString());
                                }}
                            >
                                {props.data.map((a, idx) => {
                                    return (<MenuItem key={'MenuItemTransfer' + idx} value={a.id}>{a.name}</MenuItem>);
                                })}
                            </Select>
                        </FormControl>
                        {/* <FormRequst isLoading={isLoading}  setLoading={setLoading} treeChecked={treeChecked} /> */}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.closeDialogue()}>Annuler</Button>
                <Button onClick={async (even) => await SubmittransferDatabase(even)} >Soumettre</Button>
            </DialogActions>
            <Snackbar
                    open={OpenMessageSuccess}
                    autoHideDuration={2000}
                    message="Exportation réussie d'importation"
                />
        </React.Fragment>
    );

}