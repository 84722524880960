import { useRoutes } from 'react-router-dom';
// routes
import LoginRoutes from './LoginRoutes';

// ==============================|| ROUTING RENDER ||============================== //
import { setAuthToken } from 'routes/helpers/setAuthToken';
import MainHeaderRoutes from './MainHeaderRoutes';
import MainRoutes from './MainRoutes';
export default function ThemeRoutes() {

    let isLoggedIn= false;
    //check jwt token
    const token = localStorage.getItem('user');
    if (token) {
        setAuthToken(token);
        isLoggedIn = true;
    }
    let elms = [LoginRoutes];
    if(isLoggedIn){
        elms = [MainHeaderRoutes,MainRoutes];
    }
    return useRoutes(elms);
}
