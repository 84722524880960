// material-ui
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
// project imports
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import RepeatFreqService from 'services/RepeatFreq.service';
import CustomInputTextArea from 'ui-component/Textarea/textarea';
import UrlInvocationsService from 'services/UrlInvocations.service';

// ==============================|| SAMPLE PAGE ||============================== //

function getAllMonth() {
    moment.locale('fr');
    const months = moment.localeData().months();
    const formattedMonths = months.map((month, index) => {
        return {
            value: index + 1, // Add 1 to index to get the numeric value of each month
            label: month.charAt(0).toUpperCase() + month.slice(1) // Capitalize the first letter of each month name
        };
    });

    return formattedMonths;
}
function getAllDaysOfMonth(month) {
    moment.locale('fr');
    month = month;
    const daysInMonth = moment().month(month).daysInMonth(); // Get the total number of days in the month
    const formattedDays = Array.from({ length: daysInMonth }, (_, index) => {
        const dayOfMonth = index + 1; // Add 1 to index to get the day of the month
        const date = moment().month(month).date(dayOfMonth); // Create a Moment.js object for the day without the year
        const formattedDate = date.format('dddd'); // Get the name of the day in the desired format
        return {
            value: dayOfMonth,
            label: formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1) // Capitalize the first letter of the day name
        };
    });

    return formattedDays;
}


const daysOfWeek = [
    { value: 1, label: 'Lundi' },
    { value: 2, label: 'Mardi' },
    { value: 3, label: 'Mercredi' },
    { value: 4, label: 'Jeudi' },
    { value: 5, label: 'Vendredi' },
    { value: 6, label: 'Samedi' },
    { value: 0, label: 'Dimanche' }
];
const FormUrlInvocations = (props) => {
    const [id, setId] = useState(props.idform);
    const [repeatFreq, setRepeatFreq] = useState(-1);
    const [execTime, setExecTime] = useState("");
    const [execDays, setExecDays] = useState("");
    const [libelle, setLibelle] = useState("");
    const [description, setDescription] = useState("");
    const [notifyOnFail, setNotifyOnFail] = useState(true);
    const [enabled, setEnabled] = useState(true);
    const [urltext, setUrltext] = useState("");

    const [monthSetter, setMonthSetter] = useState(-1);
    const [optionsReapat, setOptionsReapat] = useState([]);
    const [optionsReapatLength, setOptionsReapatLength] = useState(0);

    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(id, repeatFreq,execTime,execDays,description,notifyOnFail,cmdText,selectSelected);
        // console.log(values);
        await UrlInvocationsService.CreateOrUpdate(id, repeatFreq, execTime, execDays, libelle, description, notifyOnFail, enabled, urltext).then(
            (succ) => {
                props.onClose();
            },
            (err) => { console.log(err); }
        );
    }
    useEffect(() => {
        RepeatFreqService.gets().then(
            (succrepeat) => {
                setOptionsReapat(succrepeat);
                setOptionsReapatLength(succrepeat.length);
            },
            (errrepaet) => { console.log(errrepaet); }
        );
        if (id != undefined) {
            setId(id);
            UrlInvocationsService.gets(id).then(
                (succData) => {
                    setUrltext(succData.urLtext);
                    setRepeatFreq(succData.repeatFreqId);
                    setExecTime(succData.execTime);
                    setExecDays(succData.execDays);
                    setLibelle(succData.libelle);
                    setDescription(succData.description);
                    setNotifyOnFail(succData.notifyOnFail);
                    setEnabled(succData.enabled);
                },
                (err) => { console.log(err); }
            );
        }
    }, []);
    return (
        <React.Fragment>
            <form onSubmit={handleSubmit} >


                {optionsReapatLength == 0 ? (<></>) : (

                    <Stack spacing={3} direction="row" sx={{ marginBottom: 1, marginTop: 2 }}>
                        <FormControl fullWidth   >
                            <InputLabel htmlFor={"repeatFreq"}>{"Repeat Freq"}</InputLabel>

                            <Select
                                value={repeatFreq}
                                label={"Repeat Freq"}
                                labelId={"repeatFreq"}
                                variant='outlined'
                                fullWidth
                                autoWidth
                                required
                                onChange={(e) => {
                                    setRepeatFreq(e.target.value)
                                    setExecDays("")
                                    if (e.target.value === -1) {
                                        setExecTime("")
                                    }

                                    setMonthSetter(-1)
                                }}
                            >
                                <MenuItem key={'MenuItem1'} value={-1}>Choisir ... </MenuItem>
                                {optionsReapat.map((a, idx) => {
                                    return (
                                        <MenuItem key={'MenuItem1' + idx} value={a.id}>{a.label}</MenuItem>
                                    );
                                })}
                            </Select>

                        </FormControl>
                        {
                            repeatFreq == 0 ?
                                (
                                    <FormControl fullWidth  >
                                        {/* <DatePicker value={execDays} onChange={(newValue) => setExecDays(newValue)} /> */}
                                        <TextField
                                            type="date"
                                            variant='outlined'
                                            color='secondary'
                                            label="Jour"
                                            onChange={e => setExecDays(e.target.value)}
                                            value={execDays}
                                            fullWidth
                                            required
                                        />
                                    </FormControl>
                                ) :
                                (
                                    repeatFreq == 2 ? (
                                        <FormControl fullWidth  >
                                            <InputLabel htmlFor={"jour"}>{"Jours"}</InputLabel>
                                            <Select
                                                value={execDays}
                                                label={"Jours"}
                                                labelId={"jour"}
                                                variant='outlined'
                                                fullWidth
                                                autoWidth
                                                required
                                                onChange={(e) => {
                                                    setExecDays(e.target.value)
                                                }}
                                            >
                                                <MenuItem key={'MenuItem2'} value={""}>Choisir ... </MenuItem>
                                                {

                                                    daysOfWeek.map((a, idx) => {
                                                        return (
                                                            <MenuItem key={'MenuItem2' + idx} value={a.value}>{a.value + ' ' + a.label}</MenuItem>
                                                        );
                                                    })

                                                }
                                            </Select>
                                        </FormControl>

                                    ) : (
                                        repeatFreq == 3 ?
                                            (
                                                <>

                                                    <FormControl fullWidth  >
                                                        <InputLabel htmlFor={"jour"}>{"Jours"}</InputLabel>
                                                        <Select
                                                            value={execDays}
                                                            label={"Jours"}
                                                            labelId={"jour"}
                                                            variant='outlined'
                                                            fullWidth
                                                            autoWidth
                                                            required
                                                            onChange={(e) => {
                                                                setExecDays(e.target.value)
                                                            }}
                                                        >
                                                            <MenuItem key={'MenuItem3'} value={""}>Choisir ... </MenuItem>
                                                            {

                                                                Array.from({ length: 28 }, (_, i) => i + 1).map((a, idx) => {
                                                                    return (
                                                                        <MenuItem key={'MenuItem3' + idx} value={a}>{a}</MenuItem>
                                                                    );
                                                                })

                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </>
                                            ) :
                                            (
                                                repeatFreq == 4 ?
                                                    (
                                                        <>
                                                            <FormControl fullWidth  >
                                                                <InputLabel htmlFor={"mois"}>{"Mois"}</InputLabel>
                                                                <Select
                                                                    value={monthSetter}
                                                                    label={"Mois"}
                                                                    labelId={"mois"}
                                                                    variant='outlined'
                                                                    fullWidth
                                                                    autoWidth
                                                                    required
                                                                    onChange={(e) => {
                                                                        setMonthSetter(e.target.value)
                                                                        setExecDays("")
                                                                    }}
                                                                >
                                                                    <MenuItem key={'MenuItem41'} value={""}>Choisir ... </MenuItem>
                                                                    {

                                                                        getAllMonth().map((a, idx) => {
                                                                            return (
                                                                                <MenuItem key={'MenuItem41' + idx} value={a.value}>{a.value + ' ' + a.label}</MenuItem>
                                                                            );
                                                                        })

                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                            {
                                                                monthSetter >= 0 ? (
                                                                    <FormControl fullWidth  >
                                                                        <InputLabel htmlFor={"jour"}>{"Jours"}</InputLabel>
                                                                        <Select
                                                                            value={execDays}
                                                                            label={"Jours"}
                                                                            labelId={"jour"}
                                                                            variant='outlined'
                                                                            fullWidth
                                                                            autoWidth
                                                                            required
                                                                            onChange={(e) => {
                                                                                setExecDays(monthSetter + '-' + e.target.value)
                                                                            }}
                                                                        >
                                                                            <MenuItem key={'MenuItem42'} value={""}>Choisir ... </MenuItem>
                                                                            {

                                                                                getAllDaysOfMonth(monthSetter).map((a, idx) => {
                                                                                    return (
                                                                                        <MenuItem key={'MenuItem42' + idx} value={a.value}>{a.value}</MenuItem>
                                                                                    );
                                                                                })

                                                                            }
                                                                        </Select>
                                                                    </FormControl>
                                                                ) : (
                                                                    <></>

                                                                )
                                                            }

                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <></>
                                                    )
                                            )

                                    )
                                )
                        }
                        {repeatFreq == 0 || repeatFreq == 1 || repeatFreq == 2 || repeatFreq == 3 || repeatFreq == 4 ?
                            (
                                <FormControl fullWidth  >
                                    <TextField
                                        type="time"
                                        variant='outlined'
                                        color='secondary'
                                        label="Exec Time"
                                        onChange={e => setExecTime(e.target.value)}
                                        value={execTime}
                                        fullWidth
                                        required
                                    />

                                </FormControl>
                            ) : (<></>)}

                    </Stack>
                )}
                <Stack spacing={1} direction="row" sx={{ marginBottom: 1 }}>
                    <FormControl fullWidth  >
                        <TextField
                            type="text"
                            variant='outlined'
                            color='secondary'
                            label="Libelle"
                            onChange={e => setLibelle(e.target.value)}
                            value={libelle}
                            fullWidth
                            required
                            sx={{ mb: 1 }}
                        />
                    </FormControl>
                </Stack>
                <Stack spacing={1} direction="row" sx={{ marginBottom: 1 }}>
                    <FormControl fullWidth  >
                        <TextField
                            type="text"
                            variant='outlined'
                            color='secondary'
                            label="Description"
                            onChange={e => setDescription(e.target.value)}
                            value={description}
                            fullWidth
                            required
                            sx={{ mb: 1 }}
                        />
                    </FormControl>
                </Stack>
                <Stack spacing={1} direction="row" sx={{ marginBottom: 1 }}>
                    <FormControl fullWidth  >
                        <CustomInputTextArea
                            type="text"
                            variant='outlined'
                            color='secondary'
                            label="Url"
                            aria-label="Url"
                            placeholder="Url"
                            rows={2}

                            onChange={e => { setUrltext(e.target.value) }}
                            value={urltext}
                            required
                            sx={{ mb: 1 }} />
                    </FormControl>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={notifyOnFail}
                                onChange={(event) => {
                                    var value = !notifyOnFail;
                                    setNotifyOnFail(value)

                                }}
                                value={notifyOnFail}
                                color="primary"
                            />
                        }
                        label={"Notify On Fail"}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={enabled}
                                onChange={(event) => {
                                    var value = !enabled;
                                    setEnabled(value)

                                }}
                                value={enabled}
                                color="primary"
                            />
                        }
                        label={"Enabled"}
                    />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Button variant="outlined" color="secondary" type="submit">Soummetre</Button>

                </Stack>

            </form>

        </React.Fragment>


    );
};

export default FormUrlInvocations;
