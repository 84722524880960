import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';

// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';

// project imports
import SkeletonTotalGrowthBarChart from 'ui-component/cards/Skeleton/TotalGrowthBarChart';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

import MonthSelect from 'ui-component/SelectComponent/MonthSelect';
import DashbordService from 'services/dashbord.service';
// chart data


// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //


const ChartWorkerService = ({ dataCharts,statusCharts,isLoading }) => {
    const [value, setValue] = useState('today');
    const [nbServersRequstTotal, setNbServersRequstTotal] = useState(0);
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const maintenant = new Date();
    const moisActuel = maintenant.getMonth() + 1;
    const [selectedMonth, setSelectedMonth] = useState(moisActuel);
    let chartData = {
        height: 400,
        type: 'bar',
        options: {
            chart: {
                id: 'bar-chart',
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%'
                }
            },
            legend: {
                show: true,
                fontSize: '14px',
                fontFamily: `'Roboto', sans-serif`,
                position: 'bottom',
                offsetX: 20,
                labels: {
                    useSeriesColors: false
                },
                markers: {
                    width: 16,
                    height: 16,
                    radius: 5
                },
                itemMargin: {
                    horizontal: 15,
                    vertical: 8
                }
            },
            fill: {
                type: 'solid'
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                show: true
            }
        },
        xaxis: dataCharts.xaxis,
        series: dataCharts.series
    };
    const [chartsGraph, setChartsGraph] = useState(chartData);

    const handleMonthChange = event => {
      setSelectedMonth(parseInt(event.target.value, 10));
      maintenant.setMonth(parseInt(event.target.value, 10)-1); // Définir le mois spécifié (les mois commencent à partir de zéro)

        const annee = maintenant.getFullYear(); // Obtenir l'année
        const mois = maintenant.getMonth() + 1; // Obtenir le mois
        const jour = maintenant.getDate(); // Obtenir le jour

        // Formater la date au format "yyyy-MM-dd"
        const dateFormatee = `${annee}-${mois.toString().padStart(2, '0')}-${jour.toString().padStart(2, '0')}`;
        DashbordService.getsCharts(dateFormatee).then(
            (succ)=>{
                chartData.xaxis= succ.xaxis;
                chartData.series = succ.series ;
                setNbServersRequstTotal(succ.nbServersRequstTotal);

                setChartsGraph(chartData);
               
                if (!isLoading) {
                    ApexCharts.exec(`bar-chart`, 'updateOptions', chartData);
                }
                
            },
            (error)=>{
                console.log(error);
            }
        );
   
    };

    const { navType } = customization;
    const { primary } = theme.palette.text;
    const darkLight = theme.palette.dark.light;
    const grey200 = theme.palette.grey[200];
    const grey500 = theme.palette.grey[500];

    const primary200 = theme.palette.primary[200];
    const primaryDark = theme.palette.primary.dark;
    const secondaryMain = theme.palette.secondary.main;
    const secondaryLight = theme.palette.secondary.light;

    useEffect(() => {
        

        // do not load chart when loading
        if (!isLoading) {
            ApexCharts.exec(`bar-chart`, 'updateOptions', chartData);
        }
        console.log("CHart Load");
    }, [isLoading]);
    return (
        <>
            {isLoading ? (
                <SkeletonTotalGrowthBarChart />
            ) : (
                <MainCard>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="subtitle2">Nombre total de requêtes réalisées à partir de serveurs.</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3">{nbServersRequstTotal}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item >
                                    <MonthSelect selectedMonth={selectedMonth} onChange={handleMonthChange} />

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Chart {...chartsGraph} />
                        </Grid>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};

ChartWorkerService.propTypes = {
    isLoading: PropTypes.bool,
    dataCharts: PropTypes.any,
    statusCharts: PropTypes.any
};

export default ChartWorkerService;
