import axios from "axios";
import AuthServiceUser from "./AuthServiceUser";
import config from '../config';
const API_URL = config.domaineName+"/api/Servers";
const userId = config.decodeToken.userId;
const token =AuthServiceUser.getCurrentUser();
const headerToken = {
  headers: {
    'Authorization': `Bearer ${token}` 
  }
};
const getServers = async (id) => {
  if(id!=undefined){
    return await axios.get(API_URL+"/"+id, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }else{
    return await axios.get(API_URL, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }

};
const getGetAllDetailServers =  () => {
  const lspwd = config.treeServerSepcified;
  return axios.post(API_URL+"/GetAllDetailServers",lspwd, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        console.log("Response Getting 1 ...");
        return response.data;
    }
  );

};

const CreateOrUpdateServers = async (id, hostName,port,defaultCatalogue,saUserName,saPassword,nameServer) => {
  const model = {
    hostName: hostName,
    port: port,
    defaultCatalogue: defaultCatalogue,
    saUserName: saUserName,
    saPassword: saPassword,
    CreatedById:userId,
    nameServer:nameServer
  };
  if(id==0 ||id=="" || id==undefined || id==null){
    return await axios.post(API_URL , model, headerToken
      ).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }else{
    return await axios.post(API_URL+"/Put/"+id , model, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }
};
const ExcuteRequstSql = async (lsValues,requst) => {
  const model = {
    lsValues: lsValues,
    lsValuesWithPwd: config.treeServerSepcified,
    requst: requst
  };
  return await axios.post(API_URL+"/ExcuteRequstSql" , model, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
};
const DeleteServers = async (id) => {
  return await axios.post(API_URL+"/Delete/"+id , {}, headerToken).then(
      (response) => {
          if (response.status == 200) {

          }
          return response.data;
      }
  );
};


const ServerService = {
  getServers,
  getGetAllDetailServers,
  CreateOrUpdateServers,
  DeleteServers,
  ExcuteRequstSql
};

export default ServerService;
