import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Button, CardActions, CardContent, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonPopularCard from 'ui-component/cards/Skeleton/PopularCard';
import { gridSpacing } from 'store/constant';

// assets
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useEffect } from 'react';
import LogsServersService from 'services/logsServers.service';
import moment from 'moment';
import React from 'react';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const PanelLogs = ({ isLoading }) => {
    const theme = useTheme();

    const [htmlSetterRows, setHtmlSetterRows] = useState((<></>));
    const [htmlSetter, setHtmlSetter] = useState(<></>);


    const handleClose = () => {
        setHtmlSetter(<></>);
    };

    const BtnActualiser = async () => {
        setHtmlSetterRows(<></>);
        setHtmlSetter(<></>);
        await fetchData();
    }

    async function fetchData() {
        LogsServersService.gets().then(
            (ee) => {
                let logs = [];
                ee.map((item) => {
                    var datetCreated = Date.parse(item.createdAt.toString());
                    var dateToString = moment(datetCreated).format("YYYY/MM/DD kk:mm:ss");

                    var libelText = "";
                    if(item.urlInvocation){
                        libelText = "Service Invocation Url : ";
                    }else if(item.sqlCmd){
                        libelText = "Service Sql Commandes : ";
                    }else if(item.shellScript){
                        libelText = "Service Scripts Shll : ";
                    }else{
                        libelText = "Service System :";
                    }
                    logs.push(
                        <React.Fragment key={item.id} >
                            <Grid container direction="column">
                                    <Grid item>
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="subtitle1" color="inherit">
                                                    {dateToString} : 
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container alignItems="center" justifyContent="space-between">
                                                    <Grid item>
                                                        <Typography variant="subtitle1" color="inherit">
                                                            {item.status}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        {item.failed ?
                                                        (<Avatar
                                                            variant="rounded"
                                                            sx={{
                                                                width: 16,
                                                                height: 16,
                                                                borderRadius: '5px',
                                                                backgroundColor: theme.palette.orange.light,
                                                                color: theme.palette.orange.dark,
                                                                marginLeft: 1.875
                                                            }}
                                                        >
                                                            <KeyboardArrowDownOutlinedIcon fontSize="small" color="inherit" />
                                                        </Avatar>)
                                                            :
                                                            (

                                                                <Avatar
                                                                    variant="rounded"
                                                                    sx={{
                                                                        width: 16,
                                                                        height: 16,
                                                                        borderRadius: '5px',
                                                                        backgroundColor: theme.palette.success.light,
                                                                        color: theme.palette.success.dark,
                                                                        ml: 2
                                                                    }}
                                                                >
                                                                    <KeyboardArrowUpOutlinedIcon fontSize="small" color="inherit" />
                                                                </Avatar>
                                                            )}
                                                        
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                    {libelText} <br></br>
                                    {item.failed ?
                                    (<>
                                    <Typography variant="subtitle2" sx={{ color: theme.palette.orange.dark }}>
                                        
                                        {item.urlInvocation!=null?( <>
                                            libelle : {item.urlInvocation.libelle}<br></br>
                                            Description : {item.urlInvocation.description}<br></br>
                                            </>):(
                                                <>
                                                {item.sqlCmd!=null?(<>
                                                    
                                                    libelle : {item.sqlCmd.libelle}<br></br>
                                                    Description : {item.sqlCmd.description}<br></br>
                                                </>):(<>
                                                
                                                    {item.shellScript!=null?(<>
                                                        
                                                        libelle : {item.shellScript.libelle}<br></br>
                                                        Description : {item.shellScript.description}<br></br>
                                                    </>):(<></>)}
                                                </>)}
                                                
                                                </>
                                            )
                                        }
                                        <br></br>
                                        <Button sx={{ backgroundColor: theme.palette.orange.dark,color:'white'  }} 
                                            onClick={async (event) => { ShowingDesc(libelText,
                                                (<Typography variant="subtitle2" sx={{ color: theme.palette.orange.dark }}>
                                                {item.result}
                                            </Typography>)
                                            ); }}>Détail</Button>

                                        </Typography>
                                    </>)
                                        
                                        :(<>
                                        <Typography variant="subtitle2" sx={{ color: 'success.dark' }}>
                                        {item.urlInvocation!=null?( <>
                                            libelle : {item.urlInvocation.libelle}<br></br>
                                            Description : {item.urlInvocation.description}<br></br>
                                            </>):(
                                                <>
                                                {item.sqlCmd!=null?(<>
                                                    libelle : {item.sqlCmd.libelle}<br></br>
                                                    Description : {item.sqlCmd.description}<br></br>
                                                </>):(<>
                                                
                                                    {item.shellScript!=null?(<>
                                                        libelle : {item.shellScript.libelle}<br></br>
                                                        Description : {item.shellScript.description}<br></br>
                                                    </>):(<></>)}
                                                </>)}
                                                
                                                </>
                                            )
                                        }
                                        <br></br>
                                        <Button sx={{ backgroundColor: 'success.dark',color:'white' }} 
                                            onClick={async (event) => { ShowingDesc(libelText,
                                                (<Typography variant="subtitle2" sx={{ color: 'success.dark' }}>
                                                {item.result}
                                            </Typography>)
                                            ); }}>Détail</Button>
                                        </Typography>
                                        </>)
                                        }
                                        
                                    </Grid>
                                </Grid>
                                <Divider sx={{ my: 1.5 }} />

                        </React.Fragment>

                    );
                });

                setHtmlSetterRows(<React.Fragment >
                    {logs.map((itm) => {
                        return (itm);
                    })}
                </React.Fragment>);

            },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        );

    }

    useEffect(() => {
        BtnActualiser();
    }, []);

    const ShowingDesc = async (title, text) => {
        setHtmlSetter(
            <Dialog open={true} onClose={handleClose}>
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <DisabledByDefaultIcon />
                        </IconButton>


                    </DialogContentText>
                    {text}
                </DialogContent>
            </Dialog>
        );
    };

    return (
        <>
            {isLoading ? (
                <SkeletonPopularCard />
            ) : (
                <MainCard content={false}>
                    <CardContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Grid container alignContent="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="h4">Logs Applications</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {htmlSetterRows}
                            </Grid>
                        </Grid>
                    </CardContent>
                    {htmlSetter}
                </MainCard>
            )}
        </>
    );
};

PanelLogs.propTypes = {
    isLoading: PropTypes.bool
};

export default PanelLogs;
