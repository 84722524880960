import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import { DataGrid, GridCellModes } from '@mui/x-data-grid';
import {
  DataGrid,
  gridPageSelector,
  gridPageCountSelector,
  useGridApiContext,
  GridToolbarFilterButton,
  useGridSelector,
  GridCellModes
} from '@mui/x-data-grid';
import { useEffect } from 'react';
import { Alert, Pagination, Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import OneServers from 'services/oneServers.service';
import { isEmptyChildren } from 'formik';

function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {//Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

function EditToolbar(props) {
  const { selectedCellParams, cellMode, cellModesModel, setCellModesModel } = props;
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const handleSaveOrEdit = (event) => {

    if (!selectedCellParams) {
      return;
    }
    const { id, field } = selectedCellParams;

    if (cellMode === 'edit') {
      
      setCellModesModel({
        ...cellModesModel,
        [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.View } },
      });
    } else {
      
      setCellModesModel({
        ...cellModesModel,
        [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.Edit } },
      });
    }
  };

  const handleCancel = () => {
    if (!selectedCellParams) {
      return;
    }
    const { id, field } = selectedCellParams;
    setCellModesModel({
      ...cellModesModel,
      [id]: {
        ...cellModesModel[id],
        [field]: { mode: GridCellModes.View, ignoreModifications: true },
      },
    });
  };


  const handleMouseDown = (event) => {
    // Keep the focus in the cell
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        p: 1,
      }}
    >
      {/* <Pagination
      sx={(theme) => ({ padding: theme.spacing(1.5, 0) })}
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    /> */}
    <GridToolbarFilterButton />
      {/* <Button variant="outlined"
       color="primary" 
       startIcon={<AddIcon />} 
       id="AddRow"
       onClick={handleAddRow}>
        Add 
      </Button> */}
      {/* <Button
        onClick={handleSaveOrEdit}
        onMouseDown={handleMouseDown}
        disabled={!selectedCellParams}
        variant="outlined"
      >
        {cellMode === 'edit' ? 'Save' : 'Edit'}
      </Button> */}
      <Button
        onClick={handleCancel}
        onMouseDown={handleMouseDown}
        disabled={cellMode === 'view'}
        variant="outlined"
        sx={{ ml: 1 }}
      >
        Cancel
      </Button>
    </Box>
  );
}

EditToolbar.propTypes = {
  cellMode: PropTypes.oneOf(['edit', 'view']).isRequired,
  cellModesModel: PropTypes.object.isRequired,
  selectedCellParams: PropTypes.shape({
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }),
  setCellModesModel: PropTypes.func.isRequired,
};
const useFakeMutation = () => {
  return React.useCallback(
    (mode,ArrId,settersString,conditionString) =>
      new Promise((resolve, reject) => {
        if(mode=="Update"){
          // OneServers.UpdateDataByDatabaseAndTable(ArrId[0],ArrId[1],ArrId[2],settersString,conditionString).then(
          //   (succ)=>{ 
  
          //     resolve(succ);
          //   },
          //   (err)=>
          //   {reject(new Error("Error while saving can't be empty."));}
          // );
        }else if(mode=="Add"){
          // OneServers.AddDataByDatabaseAndTableAsync(ArrId[0],ArrId[1],ArrId[2],settersString,conditionString).then(
          //   (succ)=>{ 
  
          //     resolve(succ);
          //     // setValueUpdated(response);
              
          //   },
          //   (err)=>
          //   {console.log(err);reject(new Error("Error while saving can't be empty."));}
          // );
        }


      }),
    [],
  );
};
function diffObjects(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  const allKeys = new Set([...keys1, ...keys2]);

  const differences = {};
  allKeys.forEach(key => {
    if (obj1[key] !== obj2[key]) {
      differences[key] = [obj1[key], obj2[key]];
    }
  });

  return differences;
}


export default function StartEditButtonGrid(props) {
  const { columnsDynmique, rowsDynmique,idServer } = props;
  const [columnsDynmiqueState, setColumnsDynmiqueState] = React.useState([]);
  const [rowsDynmiqueState, setRowsDynmiqueState] = React.useState([]);
  const [rowsAdded, setRowsAdded] = React.useState({});
  const [selectedCellParams, setSelectedCellParams] = React.useState(null);
  const [cellModesModel, setCellModesModel] = React.useState({});
  const [valueUpdated, setValueUpdated] = React.useState({});
  const [disabledBtnAdd, setDisabledBtnAdd] = React.useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 1,
    pageCount: 3,
    page: 0,
  });

  const mutateRow = useFakeMutation();

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = React.useCallback(
    async (newRow,oldRow) => {
      // Make the HTTP request to save in the backend
      const getValuesChanged = diffObjects(oldRow,newRow);
      
      if(Object.keys(getValuesChanged).length!=0){
        var FindPK = false;

        for (var prop in columnsDynmique) {
          if(columnsDynmique[prop].CONSTRAINT_TYPE=="PRIMARY KEY" ){
            FindPK = true;
          }
        }
        for (var prop in columnsDynmique) {
          let idColumn = prop;
          if(idColumn=="id" && columnsDynmique["id"]!=undefined){
            idColumn = "Id";
          }
          if(FindPK){
            if(columnsDynmique[prop].CONSTRAINT_TYPE=="PRIMARY KEY" ){

              getValuesChanged[idColumn] = oldRow[idColumn];
            }
          }else if(columnsDynmique[prop].DATA_TYPE!="datetime2" && !Array.isArray(getValuesChanged[idColumn])){
            getValuesChanged[idColumn] = oldRow[idColumn];
          }
          
        }
        let  conditionString = "";
        let settersString = "";
        for(var colItem in getValuesChanged){
          let idColumn = colItem;
          if(idColumn=="Id" && columnsDynmique["id"]!=undefined){
            idColumn = "id";
          }
          
          if(columnsDynmique[idColumn].CONSTRAINT_TYPE=="PRIMARY KEY" ){
            if(columnsDynmique[idColumn].DATA_TYPE=="int"){
              conditionString = (conditionString!=""?conditionString+" and ":conditionString) + idColumn + "="+oldRow[colItem]+" ";

            }else{
              conditionString = (conditionString!=""?conditionString+" and ":conditionString) + idColumn + "='"+oldRow[colItem]+"' ";

            }
          }

          if(Array.isArray(getValuesChanged[colItem])){
            settersString = (settersString!=""? settersString +" , ":settersString) + idColumn + "='"+getValuesChanged[colItem][1].toString()+"' ";
            // conditionString = (conditionString!=""?" and ":"") + colItem + "='"+getValuesChanged[colItem]+"' ";
          }else if(columnsDynmique[idColumn].DATA_TYPE!="datetime2" && FindPK==false ){
            if(columnsDynmique[idColumn].DATA_TYPE=="int"){
              conditionString = (conditionString!=""?conditionString+" and ":conditionString) + idColumn + "="+oldRow[colItem]+" ";

            }else{
              conditionString = (conditionString!=""?conditionString+" and ":conditionString) + idColumn + "='"+oldRow[colItem]+"' ";

            }
            // conditionString = (conditionString!=""?conditionString+" and ":conditionString) + idColumn + "='"+oldRow[colItem]+"' ";
          }
          
          
        }
        
        
        let ArrId =idServer.split("$&-&$");
        
        const response = await mutateRow("Update",ArrId,settersString,conditionString);

        setDisabledBtnAdd(false);
        setSnackbar({ children: ' successfully saved', severity: 'success' });
        return newRow;
        
        // return newRow;
        
      }
      return oldRow;
    },
    [mutateRow],
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log(error);
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);



  const handleCellFocus = React.useCallback((event) => {
    const row = event.currentTarget.parentElement;
    const id = row.dataset.id;
    const field = event.currentTarget.dataset.field;
    setSelectedCellParams({ id, field ,valueUpdated });
  }, []);

  const cellMode = React.useMemo(() => {
    if (!selectedCellParams) {
      return 'view';
    }
    const { id, field } = selectedCellParams;
    return cellModesModel[id]?.[field]?.mode || 'view';
  }, [cellModesModel, selectedCellParams]);

  const handleCellKeyDown = React.useCallback(
    (params, event) => {
      if (cellMode === 'edit') {
        // Prevents calling event.preventDefault() if Tab is pressed on a cell in edit mode
        event.defaultMuiPrevented = true;
      }
    },
    [cellMode],
  );

  useEffect(() => {
    if(columnsDynmique!=undefined && Object.keys(columnsDynmique).length != 0){
      let varColumn = [];
       
      for (var prop in columnsDynmique) {

        let editable = false;
        let nameColumn =prop;
        let idColumn =prop;
        
        if(columnsDynmique[prop].CONSTRAINT_TYPE=="PRIMARY KEY" ){
          editable=false;
          nameColumn= "PK " +nameColumn ;
        }
        if(columnsDynmique[prop].CONSTRAINT_TYPE=="FOREIGN KEY" ){
          editable=false;
          nameColumn= "FK " +nameColumn ;
        }
        if(idColumn=="id"){
          idColumn ="Id";
        }
        var column = { field: idColumn, headerName: nameColumn,width:180, type: columnsDynmique[prop].DATA_TYPE, editable: editable };
        varColumn.push(column);

      }
      setColumnsDynmiqueState(varColumn);

      if(rowsDynmique!=undefined){
        setRowsDynmiqueState(
          rowsDynmique.map((l,idx)=>{
            if(l.id!=undefined){
              if(columnsDynmique["id"]!=undefined ){
                if(columnsDynmique["id"].DATA_TYPE=="int"){
                  l.Id = Number(l.id);;
                  
                }else{
                  l.Id = l.id;
                }
              }
            }
            l.id = idx;
            return l;
          })
        );
      }
    }

  },[rowsAdded]);

  const handleAddRow = React.useCallback(
    async (event) => {
      // Make the HTTP request to save in the backend
      let itms = rowsDynmique;
      let newItem ={};
      let arrIds = itms.map((a)=>{ return a.id;});
      newItem.id= Math.max(...arrIds) +1;
      for (var prop in columnsDynmique) {
        let idColumn = prop;
        if(idColumn=="id"){
          idColumn = "Id";
        }
        newItem[idColumn] = "";
        if(columnsDynmique[prop].DATA_TYPE=="int" ){
          newItem[idColumn] = "0";
        }else 
        if(columnsDynmique[prop].DATA_TYPE=="uniqueidentifier" ){
          newItem[idColumn] = generateUUID();
          
        }
      }
      

      let  conditionString = "";
      let settersString = "";
      for (var prop in columnsDynmique) {
        settersString = (settersString!=""? settersString +" , ":settersString) + prop ;
        let idColumn = prop;
        if(idColumn=="id" && columnsDynmique["id"]!=undefined){
          idColumn = "Id";
        }
        conditionString = (conditionString!=""?conditionString+" , ":conditionString)+"'" + newItem[idColumn]+"' ";
      }
      let ArrId =idServer.split("$&-&$");
      
      const response = await mutateRow("Add",ArrId,settersString,conditionString);

      setRowsAdded(response);
      // itms.push(response);
      // if(itms!=undefined){
      //   setRowsDynmiqueState(itms);
      // }
      // setDisabledBtnAdd(true);
      setSnackbar({ children: ' successfully saved', severity: 'success' });
      return null;
      
    },
    [mutateRow],
  );
  return (
    <div style={{ height: '84vh', width: '100%' }}>
       {/* <Button variant="outlined"
        color="primary" 
        disabled={disabledBtnAdd}
        startIcon={<AddIcon />} 
        onClick={handleAddRow}>
        Add
      </Button> */}
      <DataGrid
        rows={rowsDynmiqueState}
        columns={columnsDynmiqueState}
        onCellKeyDown={handleCellKeyDown}
        cellModesModel={cellModesModel}
        onCellModesModelChange={(model) => setCellModesModel(model)}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        hideFooter
        components={{
          Toolbar: EditToolbar,
        }}
        checkboxSelection={true}
        density={"comfortable"}
        componentsProps={{
          toolbar: {
            cellMode,
            selectedCellParams,
            setSelectedCellParams,
            cellModesModel,
            setCellModesModel,
            rowsDynmique,
            rowsDynmiqueState,
            columnsDynmique,
          },
          cell: {
            onFocus: handleCellFocus,
          },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
       {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}

