// material-ui
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button,  Dialog,  DialogActions,  DialogContent, DialogContentText, DialogTitle,
     Grid, IconButton,  Paper, Table, TableBody, TableCell, TableContainer,
      TableHead, TableRow, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useState } from 'react';
import { gridSpacing } from 'store/constant';

import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import MainCard from 'ui-component/cards/MainCard';
import EditIcon from '@mui/icons-material/Edit';
import ShellScriptsService from 'services/ShellScripts.service';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@emotion/react';
import RepeatFreqService from 'services/RepeatFreq.service';
import AccountService from 'services/account.service';
import FormUser from './FormUser';

import React from 'react'
import Select from 'react-select'
import moment from 'moment';

const UtilisateurPage = () => {
        const [isLoading, setLoading] = useState(true);
        const [rows, setRows] = useState([]);
        const [rowsSelected, setRowsSelected] = useState([]);
        const [reloadForce, setRreloadForce] = useState(true);
        const [rowsSelectedLength, setRowsSelectedLength] = useState(0);
        const [error, setError] = useState(null);
        const [openDialogue, setopenDialogue] = useState(true);
        const [htmlSetter, setHtmlSetter] = useState(<></>);
        const [optionsRoles, setOptionsRoles] = useState([]);
        const [rolesUsers, setRolesUsers] = useState([]);

        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));

        useEffect(() => {
            setLoading(true);
            fetchData();

        }, []);
        // Define functions to handle CRUD operations
        const fetchData = async () => {
            try {
                

                await AccountService.GetRoles().then(
                    async (succ)=>{
                        var arr = [];
                        succ.map((item) => {
                            let mdl = {
                                value: item.id,
                                label: <b key={'NameRole'+item.id}> {item.name} </b>
                            };
                            arr.push(mdl);
                        });
                        setOptionsRoles(arr);

                    },
                    (error) => {
                        console.log(error);
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(resMessage);
                    }
                );
                await AccountService.GetAllRolesUsers().then(
                    async (succ)=>{
                        setRolesUsers(succ);
                        setLoading(false); 
                    },
                    (error) => {
                        console.log(error);
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(resMessage);
                    }
                );
                await AccountService.GetAllUsers().then(
                    async (succ)=>{
                        setRows(succ);
                        setLoading(false);

                    },
                    (error) => {
                        console.log(error);
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(resMessage);
                    }
                );
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        }
        const handleClose = () => {
            setHtmlSetter(<></>);
        };
        const handleCreateUpdateForm = (id,row) => {
            setHtmlSetter(
                <Dialog fullScreen open={openDialogue} onClose={handleClose}>
                    <DialogTitle>
                        Nouveau Utilisateur
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                <DisabledByDefaultIcon />
                            </IconButton>
                        </DialogContentText>
                        <FormUser idform={id} item={row} onClose={BtnActualiser} />
                        {/* <FormShellScripts idform={id} onClose={BtnActualiser}  /> */}
                    </DialogContent>
                </Dialog>
            );
          };
        const handleDelete = () => {
            setHtmlSetter(
                <Dialog open={openDialogue} onClose={handleClose}>
                    <DialogTitle>
                        Supprimer Utilisateur
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                <DisabledByDefaultIcon />
                            </IconButton>
                            Êtes-vous sûr de vouloir supprimer cette ligne de tableau ?

                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>{
                        rowsSelected.map(async (itm)=>{
                            console.log("Method Deleting");
                        })
                        setRowsSelected([]);
                        setRowsSelectedLength(0);
                        BtnActualiser();
                        
                    }}
                    >
                        Oui
                    </Button>
                    <Button onClick={handleClose} >
                        Non
                    </Button>
                    </DialogActions>
                </Dialog>
            );
          };
        const BtnActualiser = async () => {
            setLoading(true);
            setHtmlSetter(<></>);
            await fetchData();
            // setRreloadForce(!reloadForce);
            setLoading(false);
            
          };
        const handleChangeRoles =  (id,event) => {
            AccountService.SetRoleUsers(id, event.map(a=>a.value)).then(
                (succ) => {
                    console.log(succ);
                },
                (error) => {
                    console.log(error);
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(resMessage);
                }
            );
          };
        return (
            <MainCard>
             <Grid
                variant="outlined"
                sx={{
                display: 'flex',
                gap: 2,
                p: 2,
                minWidth: 300,
                borderRadius: 'sm',
                }}
                container spacing={gridSpacing}>
                <Box sx={{ width: '100%' }}>

                
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Toolbar
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 }
                    }}
                    >
                    
                    {rowsSelectedLength > 0 ? (
                        <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                        >
                        {rowsSelectedLength} selected
                        </Typography>
                    ) : (
                        <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                        >
                        Utilisateur
                        </Typography>
                    )}
                    <Button size="small" variant="contained"  type='submit'  onClick={()=>handleCreateUpdateForm(undefined,undefined)} startIcon={<AddCircleIcon />}>
                        Création
                    </Button>
                    {rowsSelectedLength > 0 ? (
                        <Tooltip title="Delete">
                            <Button size="small" variant="contained"  type='submit' onClick={()=>handleDelete()}  >
                                Supprimer
                            </Button>
                        </Tooltip>
                    ) : (
                        <LoadingButton
                        size="small"
                        onClick={(e) => BtnActualiser()}
                        loading={isLoading}
                        loadingPosition="start"
                        startIcon={<></>}
                        variant="contained"
                        >
                        <span>Actualiser</span>
                    </LoadingButton>
                    )}
                    </Toolbar>
                    <TableContainer>
                        {isLoading?
                        (<></>):
                        (

                            <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={'small'}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell >
                                        <input
                                            // indeterminate={numSelected > 0 && numSelected < rowCount}
                                            // checked={rowCount > 0 && numSelected === rowCount}
                                            type='checkbox'
                                            onChange={(event) => {
                                                const {  checked } = event.target;
                                                var rowsselect = rowsSelected;
                                                const checkboxes = document.querySelectorAll('input[data-checkbox="checkedrow"]');
                                                checkboxes.forEach((checkbox) => {
                                                    checkbox.checked = checked;
                                                });
                                                if (checked) {
                                                    
                                                    rows.map((row) => {
                                                        let index = rowsselect.findIndex((a) => a == row.id);

                                                        if(index==-1){
                                                            rowsselect.push(row.id);
                                                        }
                                                        
                                                    })
                                                } else {
                                                    rows.map((row) => {
                                                        let index = rowsselect.findIndex((a) => a == row.id);

                                                        if(index!=-1){
                                                        
                                                            rowsselect.splice(index, 1);
                                                        }
                                                        
                                                    })
                                                    
                                                }
                                                setRowsSelected(rowsselect);
                                                setRowsSelectedLength(rowsselect.length);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>Profile</TableCell>
                                    <TableCell align="left">Contact</TableCell>
                                    <TableCell align="left">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => {
                                    let rolsCurrUsers = rolesUsers.map((a)=>{
                                        if(a.userId == row.id){
                                            return a.roleId;
                                        }
                                    });
                                    let defaultvalues = optionsRoles.filter((a)=>{
                                        if(rolsCurrUsers.includes(a.value)){
                                            return a;
                                        }
                                    });
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={'TableRow'+row.id}
                                            // selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                        <TableCell >
                                            <input
                                                key={'CheckboxRow'+row.id}
                                                color="primary"
                                                type='checkbox'
                                                data-checkbox="checkedrow"
                                                onChange={(event) => {
                                                    const {  checked } = event.target;
                                                    var rowsselect = rowsSelected;
                                                    let index = rowsselect.findIndex((a) => a == row.id);
                                                    if (checked) {
                                                        if(index==-1){
                                                            rowsselect.push(row.id);
                                                        }
                                                    } else {
                                                        if(index!=-1){
                                                            rowsselect.splice(index, 1);
                                                        }
                                                    }
                                                    setRowsSelected(rowsselect);
                                                    setRowsSelectedLength(rowsselect.length);
                                                }}
                                                id={'CheckboxRow'+row.id}
                                            />
                                        </TableCell>
                                        <TableCell align="left" > 
                                            Nom : {row.customerName} <br></br>
                                            Ville : {row.city} <br></br>
                                            Date de naissance : {row.dateOfBirth == undefined ? "" : moment(row.dateOfBirth).format("DD MMMM YYYY")} <br></br>
                                        </TableCell>
                                        <TableCell align="left" > 
                                            {/* Repeat Freq : {optionsReapat[row.repeatFreqId].label} <br></br> */}
                                            Adresse e-mail : {row.email} <br></br>
                                            Téléphone : {row.phoneNumber} <br></br>
                                        </TableCell>
                                        <TableCell align="left" > 
                                            <Select
                                                defaultValue={defaultvalues}
                                                isMulti
                                                name="colors"
                                                onChange={(event)=>handleChangeRoles(row.id,event)}
                                                options={optionsRoles}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                            Edit :  
                                            <IconButton key={'BtnEdit'+row.id} aria-label="edit" size="small" onClick={()=>handleCreateUpdateForm(row.id,row)} >
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                        </TableCell>
                                        
                                    </TableRow>
                                    
                                    );
                                })}
                            
                            </TableBody>
                        </Table>
                        )
                        
                    }
                    </TableContainer>
                </Paper>

                </Box>
                {htmlSetter}


            </Grid>
       </MainCard>
           
        );
};

export default UtilisateurPage;
