import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import {  Link, useNavigate } from 'react-router-dom';
// assets
import { IconMenu2 } from '@tabler/icons';
import menuHeaderRoutes from 'routes/MainHeaderRoutes';
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle,showingSideBar }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const handleListItemClick = (event, index, route = '') => {
        if (route && route !== '') {
            navigate(route);
        }
    };
    const navItems = menuHeaderRoutes.children.map((item) => {
        if(item.path!='/'){
            let lnk = (<></>);
            if(item.path.includes('http')){
                lnk = (<a href={item.path} target='_blank' relative="path">{item.text}</a>);
            }else{
                lnk = (<ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }} onClick={(event) => handleListItemClick(event, 0, item.path)}>{item.text}</ButtonBase>);
            }
            return (
                <Box key={item.path} sx={{ flexGrow: 1 }} >
                    {lnk}
                </Box>
            );
        }
    });

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {showingSideBar?(<>
                    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ButtonBase>
                
                </>):(<></>)}
            </Box>

            <Box sx={{ flexGrow: 1 }} />
                {navItems}
            <Box sx={{ flexGrow: 1 }} />
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
