// material-ui
import { AspectRatio, CheckBox } from '@mui/icons-material';
import { Backdrop, Badge, Button, ButtonGroup, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, Input, InputLabel, List, ListItem, ListItemText, MenuItem, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { gridSpacing } from 'store/constant';
import FullScreenDialog from 'ui-component/Modal/FullScreenDialog';
import MainCard from 'ui-component/cards/MainCard';
// project imports
import IndexServersPage from 'views/crud-servers';
import FormRequst from 'views/crud-servers/FormRequst';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ServerService from 'services/server.service';
import EditIcon from '@mui/icons-material/Edit';
import FormServer from 'views/crud-servers/FormServer';
import FormAddDatabaseServer from 'views/crud-servers/FormAddDatabaseServer';
import FullWidthDialog from 'ui-component/Modal/FullWidthDialog';
import OneServers from 'services/oneServers.service';
import DialogBackups from 'ui-component/Modal/DialogBackups';
import RestoreDbService from 'services/RestoreDbs.service';
import BackUpService from 'services/BackUpDb.service';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DynamiqueMenuButton from 'ui-component/Tabs/DynamiqueMenuButton';
import DescriptionIcon from '@mui/icons-material/Description';
import Rows from './Rows';
import StartEditButtonGrid from 'ui-component/DataGrid/StartEditButtonGrid';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { LoadingButton } from '@mui/lab';
import { Suspense } from 'react';
import TransferDataBase from './transfer-database';
import InstanceService from './InstanceService';
import BackupForm from './BackupForm';
import config from 'config';
import SetPasswordServer from 'views/crud-servers/SetPasswordServer';
import FormLabelDatabaseServer from 'views/crud-servers/FormLabelDatabaseServer';
import ServerDataBasesService from 'services/ServerDataBases.service';
// ==============================|| SAMPLE PAGE ||============================== //

const API_URL = config.domaineName + "/api/";
const fetchingdataWidgert = async () => {
    const res = await fetch(API_URL+"Dashbord/Widgets");
    const jsonData = await res.json();
    console.log(jsonData);
    return jsonData;
  };

const InstancesPage = () => {
    const [isLoading, setLoading] = useState(true);
    const [treeChecked, setTreeChecked] = useState([]);
    const [treeServers, setTreeServers] = useState([]);
    const [permission] = useState(config.decodeToken);
    const [treeCheckedLength, setTreeCheckedLength] = useState(0);
    const [data, setData] = useState([]);
    const [openDilog, setOpenDilog] = useState(true);
    const [dialogFullWith, setDialogFullWith] = useState({
        openClose: false,
        component: <div></div>
    });
    const [dialogFullScreen, setDialogFullScreen] = useState({
        openClose: false,
        component: <div></div>
    });

    const [htmlSetter, setHtmlSetter] = useState((<></>));
    const [htmlSetterRows, setHtmlSetterRows] = useState((<></>));
    const [openLoading, setOpenLoading] = useState(false);
    const [inputPwdServer, setInputPwdServer] = useState("");


    const UpdateServerForm = async (event, id) => {
        var arrIds = id.split("$&-&$");
        if (arrIds.length == 1) {
            // setLoading(true);
            var htmlserver = <></>;
            await ServerService.getServers(id).then(
                (succ) => {
                    let infoDataGetted = {
                        id: succ.id,
                        hostName: succ.hostName,
                        port: succ.port,
                        defaultCatalogue: succ.defaultCatalogue,
                        saUserName: succ.saUserName,
                        saPassword: succ.saPassword,
                        nameServer: succ.nameServer
                    };
                    htmlserver = <FormServer
                        id={id}
                        isLoading={isLoading} setLoading={setLoading}
                        onClose={BtnActualiser}
                        dataParent={data}
                        setDataParent={setData}
                        getDataInfo={infoDataGetted} />;

                    // setOpenDilog(true);

                    // setLoading(false);
                },
                (error) => {
                    console.log(error);
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(resMessage);
                }
            );
            setHtmlSetter(
                (
                    <Dialog maxWidth={'xl'} onClose={closeDialogue} open={true}>
                        <DialogTitle></DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <IconButton
                                    aria-label="close"
                                    onClick={closeDialogue}
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <DisabledByDefaultIcon />
                                </IconButton>
                            </DialogContentText>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    {htmlserver}
                                </Grid>
                            </Grid>
                        </DialogContent>

                    </Dialog>
                )
            );
        }
    }
    const CreateServerForm = () => {

        setHtmlSetter(<Dialog maxWidth={'xl'} onClose={closeDialogue} open={true}>
            <DialogTitle></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <IconButton
                        aria-label="close"
                        onClick={closeDialogue}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <DisabledByDefaultIcon />
                    </IconButton>
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <FormServer
                            dataParent={data}
                            setDataParent={setData}
                            onClose={BtnActualiser}
                            getDataInfo={{
                                id: "",
                                hostName: "localhost",
                                port: 0,
                                defaultCatalogue: "master",
                                saUserName: "sa",
                                saPassword: ""
                            }} />
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>);

    };
    const CreateUpdateDatabaseForm = async (event, id) => {
        var arrIds = id.split("$&-&$");
        if (arrIds.length == 1) {
            // setLoading(true);
            await ServerService.getServers(id).then(
                (succ) => {
                    let infoDataBaseGetted = {
                        id: succ.id,
                        nameDatabase: ""
                    };
                    setHtmlSetter(<Dialog maxWidth={'xl'} onClose={closeDialogue} open={true}>
                        <DialogTitle></DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <IconButton
                                    aria-label="close"
                                    onClick={closeDialogue}
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <DisabledByDefaultIcon />
                                </IconButton>
                            </DialogContentText>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <FormAddDatabaseServer
                                        isLoading={isLoading} setLoading={setLoading}
                                        onClose={BtnActualiser}
                                        dataParent={data}
                                        setDataParent={setData}
                                        getDataInfo={infoDataBaseGetted} />
                                </Grid>
                            </Grid>
                        </DialogContent>

                    </Dialog>);
                },
                (error) => {
                    console.log(error);
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(resMessage);
                }
            );
        }
    }
    const DeleteDatabaseForm = async (id) => {
        var arrIds = id.split("$&-&$");
        if (arrIds.length == 2) {
            // setLoading(true);
            setHtmlSetter(<Dialog maxWidth={'xl'} onClose={closeDialogue} open={true}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <IconButton
                            aria-label="close"
                            onClick={closeDialogue}
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <DisabledByDefaultIcon />
                        </IconButton>
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            Voulez-vous vraiment supprimer cette base de données ?
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialogue}>No</Button>
                    <Button onClick={async (event) => {
                        event.preventDefault();
                        setOpenLoading(true);
                        await OneServers.DeleteDataBases(arrIds[0], arrIds[1]).then(
                            (ee) => { 
                                setOpenLoading(false);
                                console.log(ee); 
                            },
                            (error) => {
                                setOpenLoading(false);
                                console.log(error);
                                const resMessage =
                                    (error.response &&
                                        error.response.data &&
                                        error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                console.log(resMessage);
                            }
                        );

                        BtnActualiser();
                    }}>Yes</Button>
                </DialogActions>
            </Dialog>);
        }
    }
    const UploadDatabase = async (event, id) => {
        // setLoading(true);
        setHtmlSetter(
            (
                <Dialog maxWidth={'xl'} onClose={closeDialogue} open={openDilog}>
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <IconButton
                                aria-label="close"
                                onClick={closeDialogue}
                                sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <DisabledByDefaultIcon />
                            </IconButton>
                        </DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <BackupForm ServerId={id} />
                                {/* <Button variant="contained" component="label">
                                    Upload
                                    <input hidden accept="*.bak" type="file" />
                                </Button> */}
                            </Grid>
                        </Grid>
                    </DialogContent>

                </Dialog>

            )
        );
    }
    const HandelClickFormLabelDatabaseServer = async (event, idServer, database) => {

        ServerDataBasesService.gets(database,idServer).then(
            (succ)=>{
                let infoDataGetted = {
                    nameDatabase: database,
                    nameDatabseSpecified: succ.nameDatabseSpecified,
                    description: succ.description
                };
                setHtmlSetter(
                    (
                        <Dialog maxWidth={'xl'} onClose={closeDialogue} open={openDilog}>
                            <DialogTitle></DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <IconButton
                                        aria-label="close"
                                        onClick={closeDialogue}
                                        sx={{
                                            position: 'absolute',
                                            right: 0,
                                            top: 0,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <DisabledByDefaultIcon />
                                    </IconButton>
                                </DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <FormLabelDatabaseServer id={database} ServerId={idServer} getDataInfo={infoDataGetted} onClose={BtnActualiser} />
                                    </Grid>
                                </Grid>
                            </DialogContent>
        
                        </Dialog>
        
                    )
                );
            },
            (err)=>{console.log(err);}
            );
        
    }

    const DownloadHistoriqueBackupDatabase = async (value) => {
        // setHtmlSetter((<></>));
        await BackUpService.DownloadBackUpById(value).then(
            (ee) => { console.log("BackUp Sucess"); },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        )
    }
    const getHistoriqueBackupDatabase = async (curr) => {
        event.preventDefault();
        var arrIds = curr.split("$&-&$");
        await BackUpService.getBackUpBYServerIdAndDatabase(arrIds[0], arrIds[1]).then(
            (ee) => {
                const data = ee.map((obj, idx) => {
                    const date = new Date(obj.createdAt);
                    const formattedDate = date.toLocaleString('fr-FR', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                        timeZone: 'Africa/Tunis'
                    }).replace(/,/g, '');
                    const action = (
                        <Button
                            onClick={() => DownloadHistoriqueBackupDatabase(obj.id)}
                            key={'ListItemButton' + obj.id}
                            variant="contained">
                            <DownloadForOfflineIcon />
                        </Button>
                    );
                    return {
                        label: obj.nameDataBase + ' ' + formattedDate,
                        id: obj.id,
                        action: action
                    }
                });
                let openDialogue = true;
                setHtmlSetter(
                    (
                        <DialogBackups
                            key={curr}
                            keyOption={curr}
                            selectedValue={''}
                            open={openDialogue}
                            options={data}
                            actionAdd={(
                                <>
                                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                        <Button onClick={async () => {
                                            await InstanceService.getbackup(curr);
                                            await setHtmlSetter((<></>));
                                            await getHistoriqueBackupDatabase(curr);
                                        }}
                                            variant="contained">
                                            {'Ajouer ' + arrIds[1] + ' Sauvgarde'}
                                        </Button>
                                    </ButtonGroup>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => { setHtmlSetter((<></>)); }}
                                        sx={{
                                            position: 'absolute',
                                            right: 0,
                                            top: 0,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <DisabledByDefaultIcon />
                                    </IconButton>
                                </>

                            )}
                            nameoflist={arrIds[1] + ' Sauvgarde'}

                        />
                    )
                );
            },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        );
    }
    const ClieckedRestoreDatabase = async (value, name) => {
        // setHtmlSetter((<></>));
        await RestoreDbService.ExcuteRequstSQlgetRestoreDb(value, name).then(
            (ee) => { },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        )
    }
    const getRestoreDatabase = async (curr) => {
        event.preventDefault();
        var arrIds = curr.split("$&-&$");
        await BackUpService.getBackUpBYServerIdAndDatabase(arrIds[0], arrIds[1]).then(
            (ee) => {
                const data = ee.map((obj, idx) => {
                    const date = new Date(obj.createdAt);
                    const formattedDate = date.toLocaleString('fr-FR', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                        timeZone: 'Africa/Tunis'
                    }).replace(/,/g, '');
                    const action = (
                        <Button
                            onClick={() => ClieckedRestoreDatabase(obj.id, obj.nameDataBase)}
                            key={'ListItemButton' + obj.id}
                            variant="contained">
                            <RestoreFromTrashIcon />
                        </Button>
                    );
                    return {
                        label: obj.nameDataBase + ' ' + formattedDate,
                        id: obj.id,
                        action: action
                    }
                });
                let openDialogue = true;
                setHtmlSetter(
                    (
                        <DialogBackups
                            key={curr}
                            keyOption={curr}
                            selectedValue={''}
                            open={openDialogue}
                            options={data}
                            actionAdd={(
                                <>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => { setHtmlSetter((<></>)); }}
                                        sx={{
                                            position: 'absolute',
                                            right: 0,
                                            top: 0,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <DisabledByDefaultIcon />
                                    </IconButton>
                                </>

                            )}
                            nameoflist={arrIds[1] + ' Sauvgarde'}

                        />
                    )
                );
            },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        );
    }
    const handleCheckedCheckboxDatabase = (e, curr) => {
        const { value, checked } = e.target;
        var arr = JSON.parse(localStorage.getItem('treeChecked'));
        if (checked) {
            arr.push(value);
        } else {
            let index = arr.findIndex((a) => a == value);
            arr.splice(index, 1);
        }

        localStorage.setItem('treeChecked', JSON.stringify(arr));

        setTreeChecked(arr);
        setTreeCheckedLength(arr.length);

    }




    const SetPasswordToServer = (e, idServer,pwd) => {
        
        setHtmlSetter(<Dialog maxWidth={'xl'} onClose={closeDialogue} open={true}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <IconButton
                            aria-label="close"
                            onClick={closeDialogue}
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <DisabledByDefaultIcon />
                        </IconButton>
                    </DialogContentText>
                    <SetPasswordServer idServer={idServer} pwd={pwd} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialogue}>Fermet</Button>
                    {/* <Button onClick={async (event) => {
                        event.preventDefault();
                        setOpenLoading(true);
                        

                    }}>Oui</Button> */}
                </DialogActions>
            </Dialog>);
    }
    const handleCheckedCheckboxAllDatabase = (e, curr) => {
        const { checked } = e.target;
        const checkboxes = document.querySelectorAll('input[data-checking="' + curr + '"]');
        var arr = JSON.parse(localStorage.getItem('treeChecked'));
        checkboxes.forEach((checkbox) => {

            checkbox.checked = checked;
            let index = arr.findIndex((a) => a == checkbox.value);
            if (checked) {
                if (index == -1) {
                    arr.push(checkbox.value);
                }
            } else {
                if (index != -1) {
                    arr.splice(index, 1);
                }
            }
        });

        localStorage.setItem('treeChecked', JSON.stringify(arr));

        setTreeChecked(arr);
        setTreeCheckedLength(arr.length);

    }
    const NewRequst = (e) => {
        setOpenDilog(true);
        setHtmlSetter(
            (
                <Dialog fullScreen onClose={closeDialogue} open={openDilog}>
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <IconButton
                                aria-label="close"
                                onClick={closeDialogue}
                                sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <DisabledByDefaultIcon />
                            </IconButton>
                        </DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <FormRequst isLoading={isLoading} setLoading={setLoading} treeChecked={treeChecked} />
                            </Grid>
                        </Grid>
                    </DialogContent>

                </Dialog>

            )
        );

    }

    const TransferDatabase = () => {

        // setOpenDilog(true);
        setHtmlSetter(
            (
                <Dialog
                    fullWidth={true}
                    maxWidth={'xl'}
                    onClose={closeDialogue}
                    open={true}>
                    <List dense={true}>
                        {treeChecked.map((curr, idx) => {
                            var arrIds = curr.split("$&-&$");
                            return (

                                <ListItem
                                    key={"AlerteDataBase" + idx}
                                    secondaryAction={
                                        <DynamiqueMenuButton
                                            options={
                                                [
                                                    {
                                                        label: 'Sauvegarde de Base de données',
                                                        func: async (e) => await InstanceService.getbackup(curr)
                                                    },
                                                    {
                                                        // label :<DialogBackups key={'History'+idx} keyoption={'KeyOptionHistory'+idx} btnname={'L\'historiques des Base de données'} /> ,
                                                        label: 'Historiques des Base de données',
                                                        func: async (e) => await getHistoriqueBackupDatabase(curr)

                                                    },
                                                    {
                                                        label: 'Restauration des Base de données',
                                                        func: async (e) => await getRestoreDatabase(curr)
                                                    }
                                                    ,
                                                    {
                                                        label: 'Supprimer Base de donnée',
                                                        func: async (e) => await DeleteDatabaseForm(idServ + '$&-&$' + database)
                                                    }
                                                ]
                                            }
                                        />
                                    }
                                >
                                    <ListItemText primary={arrIds[1]} />
                                </ListItem>


                            );
                        })}
                    </List>
                    <TransferDataBase
                        closeDialogue={closeDialogue}
                        data={data}
                        BtnActualiser={BtnActualiser}
                        OpenLoading={setOpenLoading}
                        treeChecked={treeChecked} />
                </Dialog>

            )
        );

    }
    const closeDialogue = () => {
        console.log("Dialogue Closed");
        setLoading(true);
        // setOpenDilog(false);
        setHtmlSetter(<></>);
        // fetchData();
        // setHtmlSetterRows(<Rows  isLoading={isLoading} rows={data} treeChecked={treeChecked} />);
        setLoading(false);
    }
    const BtnActualiser = () => {
        setLoading(true);
        // setOpenDilog(false);
        setHtmlSetterRows(<></>);
        setHtmlSetter(<></>);
        setTreeChecked([]);
        localStorage.setItem('treeChecked', JSON.stringify([]));
        setTreeCheckedLength(0);
        fetchData();

        // setHtmlSetterRows(<Rows isLoading={isLoading} rows={data} treeChecked={treeChecked} />);
        // setLoading(false);
    }

    const deleteRequest = async (event, id) => {
        setHtmlSetter(<Dialog maxWidth={'xl'} onClose={closeDialogue} open={true}>
            <DialogTitle></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <IconButton
                        aria-label="close"
                        onClick={closeDialogue}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <DisabledByDefaultIcon />
                    </IconButton>
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        Voulez-vous vraiment retirer ce serveur ?
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialogue}>No</Button>
                <Button onClick={async (event) => {
                    event.preventDefault();
                    closeDialogue();
                    await ServerService.DeleteServers(id).then(
                        (succ) => {
                            
                            BtnActualiser();
                        },
                        (error) => {
                            console.log(error);
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            console.log(resMessage);
                        }
                    );
                }}>Yes</Button>
            </DialogActions>
        </Dialog>);


    }
    const handleClickOpenDialgFullScreen = (e, itm) => {
        let ArrId = itm.id.split("$&-&$");

        OneServers.GetAllDataByDatabaseAndTable(ArrId[0], ArrId[1], ArrId[2]).then(
            (succ) => {

                let rowss = succ.filter((row, idx) => { if (idx > 0) { return row } });
                let colss = succ[0];
                console.log(colss);
                console.log(rowss);
                console.log(itm.id);
                let mddl = {
                    openClose: true,
                    component: <StartEditButtonGrid idServer={itm.id} rowsDynmique={rowss} columnsDynmique={colss} />
                };
                setDialogFullScreen(mddl);
            },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        );
    };
    
    const DownloadBackupDatabase = async (path) => {
        setOpenLoading(true);
        await InstanceService.getbackup(path);
        setOpenLoading(false);
    };
    function fetchData() {
        setLoading(true);
        let servers = []
        var arrTreeServer = config.treeServerSepcified;
        ServerService.getGetAllDetailServers().then(
            (ee) => {
                
                if(arrTreeServer==undefined){
                    arrTreeServer = [];
                }
                ee.map((itemsServers) => {
                    let itemServers = itemsServers.server;
                    var databases = itemsServers.databases;
                    var idServ = itemServers.id;


                    var chlidserver = [];

                    let chldrns = [];
                    databases.map(async (databaseDto) => {
                        let database = databaseDto.database;
                        let succTables = databaseDto.tables;
                        var childDatabase = [];
                        let childtable = [];
                        succTables.map(async (tableDto) => {
                            var table = tableDto.table;
                            var succColumns = tableDto.colDynamic;
                            let childColumns = [];
                            var checkprimerykey = false;

                            let childColumn = [];
                            for (var prop in succColumns) {
                                var column = succColumns[prop];
                                if (column['cONSTRAINT_TYPE'] == "PRIMARY KEY") {
                                    checkprimerykey = true;
                                }
                                let mdll = {
                                    id: idServ + "$&-&$" + database + "$&-&$" + table + "$&-&$" + column['cOLUMN_NAME'],
                                    name: <div> <b>{column['cOLUMN_NAME']} : {column['cONSTRAINT_TYPE']}</b>  Nullable :  {column['iS_NULLABLE']}  Type :  {column['dATA_TYPE']} </div>,
                                    label: <b>{column['cOLUMN_NAME'] + ' Valeurs : '}</b>,
                                    labelColum: 'Valeurs',
                                    actions: <div key={"DivActionServer" + idServ + "$&-&$" + database + "$&-&$" + column['cOLUMN_NAME']}></div>,
                                    children: []
                                };
                                childColumn.push(mdll);
                            }
                            childColumns = childColumn;


                            var valuesactions = permission.role?.includes("Admin") ? (
                                <IconButton onClick={(e) => handleClickOpenDialgFullScreen(e, mdl)} size="small" aria-label="edit">
                                    <EditIcon fontSize='small' />
                                </IconButton>
                            ) : (<></>);
                            if (!checkprimerykey) {
                                valuesactions = undefined;
                            }
                            let mdl = {
                                id: idServ + "$&-&$" + database + "$&-&$" + table,
                                name: <b> {table} </b>,
                                label: table + ' Colonnes : ',
                                labelColum: 'Colonnes',
                                actions: <div key={"DivActionServer" + idServ + "$&-&$" + database + "$&-&$" + table}>
                                    {valuesactions}

                                </div>,
                                children: childColumns
                            };
                            childtable.push(mdl);

                        });
                        childDatabase = childtable;
                        setLoading(false);

                        let optionsDynamiqueMenuButton = [
                            {
                                label: 'Sauvegarde de Base de données',
                                func: async (e) => await DownloadBackupDatabase(idServ + '$&-&$' + database) 
                            },
                            {
                                // label :<DialogBackups key={'History'+idx} keyoption={'KeyOptionHistory'+idx} btnname={'L\'historiques des Base de données'} /> ,
                                label: 'Historiques des Base de données',
                                func: async (e) => await getHistoriqueBackupDatabase(idServ + '$&-&$' + database)

                            },
                            {
                                label: 'Restauration des Base de données',
                                func: async (e) => await getRestoreDatabase(idServ + '$&-&$' + database)
                            },
                        ];
                        if (permission.role?.includes("Admin")) {
                            optionsDynamiqueMenuButton.push({
                                label: 'Supprimer Base de donnée',
                                func: async (e) => await DeleteDatabaseForm(idServ + '$&-&$' + database)
                            });
                        }
                        let mdl = {
                            id: idServ + '$&-&$' + database,
                            name: <b>{database +' => '+ databaseDto.databaseServer?.nameDatabseSpecified}</b>,
                            serverid: idServ,
                            label: database + ' Tables : ',
                            labelColum: <> Nom de Table : </>,
                            dernierebackup: "",
                            actions: <>
                                <Button variant="outlined"
                                    size="small"
                                    onClick={async (event) => await HandelClickFormLabelDatabaseServer(event, idServ, database)}
                                    startIcon={<EditIcon fontSize="inherit" />}>
                                    Ajouter Nom Client
                                </Button>
                                <DynamiqueMenuButton
                                    key={'DynamiqueMenuButton' + idServ + '$&-&$' + database}
                                    options={optionsDynamiqueMenuButton}
                                />
                            </>
                            ,

                            check: <input
                                key={"InputCheckBox" + idServ + '$&-&$' + database}
                                type="checkbox"
                                data-checking={'InputCheckBox' + idServ}
                                id={"InputCheckBox" + idServ + '$&-&$' + database}
                                value={idServ + "$&-&$" + database}
                                onChange={(e, itm) => handleCheckedCheckboxDatabase(e, idServ + "$&-&$" + database)}
                            />,
                            children: childDatabase,
                        };
                        chldrns.push(mdl);
                    });
                    chlidserver = chldrns;

                    let indexTreeServer = arrTreeServer.findIndex((a) => a.id == idServ);
                    let pwdServer = "";
                    if(indexTreeServer==-1){
                        pwdServer = itemServers.saPassword
                        arrTreeServer.push({ id : idServ, pwd:itemServers.saPassword});
                    }else{
                        let itmServrLS = arrTreeServer.find((a) => a.id == idServ);
                        pwdServer = itmServrLS.pwd
                    }
                    
                    servers.push(
                        {
                            id: itemServers.id,
                            name: <b>{itemServers.nameServer+' => '+itemServers.hostName + ',' + itemServers.port} </b>,
                            label: 'Bases De Données',
                            labelColum: <>

                                <input
                                    key={"InputCheckBoxAll" + idServ}
                                    type="checkbox"
                                    onChange={(e, itm) => handleCheckedCheckboxAllDatabase(e, 'InputCheckBox' + idServ)}
                                />Nom de Base de Données
                            </>,
                            actions: <div key={"DivActionServer" + itemServers.id}>
                                <Grid container 
                                    spacing={gridSpacing}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <SetPasswordServer key={"InputCheckBoxAllPwd" + idServ}  idServer={idServ} pwd={pwdServer} />
                                        {/* <Button  key={"InputCheckBoxAllPwd" + idServ}  onClick={(e) => SetPasswordToServer(e, idServ,pwdServer)}
                                        >Mot de Passe</Button> */}
                                    </Grid>
                                    {permission.role?.includes("Admin") ? (
                                        <>
                                            <Grid item>
                                                <Button variant="outlined"
                                                    size="small"
                                                    onClick={async (event) => await UpdateServerForm(event, itemServers.id)}
                                                    startIcon={<EditIcon fontSize="inherit" />}>
                                                    Modifier un serveur
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                
                                                <Button variant="outlined"
                                                    size="small"
                                                    onClick={async (event) => await deleteRequest(event, itemServers.id)}
                                                    startIcon={<RemoveCircleIcon fontSize="inherit" />}>
                                                    Retirer un serveur
                                                </Button>
                                            </Grid>

                                        </>

                                    ) : (<></>)}
                                    
                                    <Grid item>
                                        <Button variant="outlined"
                                            size="small"
                                            onClick={async (event) => await UploadDatabase(event, itemServers.id)}
                                            startIcon={<AddCircleOutlineIcon fontSize="inherit" />}>
                                            Ajouter une base de données
                                        </Button>
                                    </Grid>
                                    <Grid item >

                                        <Button variant="outlined"
                                            size="small"
                                            onClick={async (event) => await CreateUpdateDatabaseForm(event, itemServers.id)}
                                            startIcon={<AddCircleOutlineIcon fontSize="inherit" />}>
                                            Créer une base de données
                                        </Button>
                                    </Grid>
                                </Grid>


                            </div>,
                            children: chlidserver,
                        },
                    );
                }
                );
                localStorage.setItem('treeServer', JSON.stringify(arrTreeServer));
                setData(servers);

                localStorage.setItem('treeChecked', JSON.stringify(treeChecked));
                setLoading(false);
            },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        );

    }
    useEffect(() => {
        setHtmlSetterRows(<Rows isLoading={isLoading} rows={data} treeChecked={treeChecked}      />);
    }, [data]);


    useEffect(() => {
        BtnActualiser();
    }, []);

    return (
        <MainCard>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 }
                }}
            >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h2"
                    id="tableTitle"
                    component="div"
                >
                    Liste des serveur
                </Typography>
            </Toolbar>
            <TableContainer component={Paper}>
                <Grid container
                    spacing={gridSpacing}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Button variant="outlined" size="small"
                            spacing={1}
                            onClick={(e) => CreateServerForm()}
                            startIcon={<AddCircleOutlineIcon fontSize="inherit" />}>
                            Connecter à un nouveau Serveur SQL
                        </Button>
                    </Grid>
                    <Grid item>
                        <LoadingButton
                            spacing={1}
                            size="small"
                            onClick={(e) => BtnActualiser()}
                            loading={isLoading}
                            loadingPosition="start"
                            startIcon={<></>}
                            variant="contained"
                        >
                            <span>Actualiser</span>
                        </LoadingButton>
                    </Grid>
                </Grid>



                <Table size="small" aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Nom de serveur</TableCell>
                            <TableCell align="left">
                                <Grid container
                                    spacing={gridSpacing}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        Actions
                                    </Grid>
                                    {treeCheckedLength == 0 ? (<></>) : (
                                        <>
                                            <Grid item>
                                                <Button variant="outlined" size="small" onClick={() => NewRequst()} startIcon={<AddCircleOutlineIcon fontSize="inherit" />}>
                                                    Nouvelle requête {treeCheckedLength}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="outlined" size="small" onClick={() => TransferDatabase()} startIcon={<AddCircleOutlineIcon fontSize="inherit" />}>
                                                    Importer les bases de données à un serveur différent. {treeCheckedLength}
                                                </Button>
                                            </Grid>

                                        </>

                                    )}


                                </Grid>


                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading == false ? (htmlSetterRows) : (<></>)}
                    </TableBody>
                </Table>
            </TableContainer>

            <FullWidthDialog isLoading={isLoading} setLoading={setLoading} setDialogFullWith={setDialogFullWith} openClose={dialogFullWith.openClose} component={dialogFullWith.component} />
            <FullScreenDialog isLoading={isLoading} setLoading={setLoading} setDialogFullScreen={setDialogFullScreen} openClose={dialogFullScreen.openClose} component={dialogFullScreen.component} />

            {htmlSetter}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoading}
                onClick={()=>{ console.log("Backdrop Clicked");}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
        </MainCard>

    );
};

export default InstancesPage;
