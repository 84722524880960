import axios from "axios";
import AuthServiceUser from "./AuthServiceUser";
import config from '../config';
const API_URL = config.domaineName+"/api/Dashbord";
const token =AuthServiceUser.getCurrentUser();
const headerToken = {
  headers: {
    'Authorization': `Bearer ${token}` 
  }
};
const gets = async () => {
  return await axios.get(API_URL+"/Widgets", headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );

};
const getsCharts = async (date) => {
  if(date!=undefined){
    return await axios.get(API_URL+"/Charts?date="+date, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }else{
    return await axios.get(API_URL+"/Charts", headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }


};



const DashbordService = {
  gets,
  getsCharts
};

export default DashbordService;
