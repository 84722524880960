// material-ui
import { Checkbox, FormControl, Grid, FormControlLabel, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, useMediaQuery, Input } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
// project imports
import { Formik } from 'formik';
import { Box } from '@mui/system';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import OneServers from 'services/oneServers.service';
import config from 'config';
// ==============================|| SAMPLE PAGE ||============================== //

const SetPasswordServer = (props) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [inputPwdServer, setInputPwdServer] = useState(props.pwd);

    const ChangePasswordOnServer = (e, idServer) =>{
        const { value } = e.target;
        var arr = config.treeServerSepcified;
        let index = arr.findIndex((a) => a.id == idServer);
        if(index!= -1 ){
            arr.find((a) => a.id == idServer).pwd = value
        }
        setInputPwdServer(value);
        localStorage.setItem('treeServer', JSON.stringify(arr));
    }
    useEffect(() => {
        
    },[]);
    return (
        <Input type='text' key={"InputCheckBoxAllPwd" }
        placeholder='Password' 
        value={inputPwdServer}
        onChange={(e, itm) => ChangePasswordOnServer(e, props.idServer)}
        />
    );
  };

export default SetPasswordServer;
