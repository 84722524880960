import React from 'react';
import PropTypes from 'prop-types';

const MonthSelect = ({ selectedMonth, onChange }) => {
  const months = [
    { value: 1, label: 'Janvier' },
    { value: 2, label: 'Février' },
    { value: 3, label: 'Mars' },
    { value: 4, label: 'Avril' },
    { value: 5, label: 'Mai' },
    { value: 6, label: 'Juin' },
    { value: 7, label: 'Juillet' },
    { value: 8, label: 'Août' },
    { value: 9, label: 'Septembre' },
    { value: 10, label: 'Octobre' },
    { value: 11, label: 'Novembre' },
    { value: 12, label: 'Décembre' },
  ];

  return (
    <select value={selectedMonth} onChange={onChange}>
      {months.map(month => (
        <option key={month.value} value={month.value}>
          {month.label}
        </option>
      ))}
    </select>
  );
};

MonthSelect.propTypes = {
  selectedMonth: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MonthSelect;
