import axios from "axios";
import AuthServiceUser from "./AuthServiceUser";
import config from '../config';
const API_URL = config.domaineName+"/api/ServerDataBases";
const userId = config.decodeToken.userId;
const token =AuthServiceUser.getCurrentUser();
const headerToken = {
  headers: {
    'Authorization': `Bearer ${token}` 
  }
};
const gets = async (id,idServer) => {
  if(id!=undefined){
    return await axios.get(API_URL+"/"+idServer+"?Id="+id, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }else{
    return await axios.get(API_URL, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }

};

const CreateOrUpdate = async (id, serversId,nameDatabse,nameDatabseSpecified,description) => {
  const model = {
    id: id,
    serversId: serversId,
    nameDatabse: nameDatabse,
    nameDatabseSpecified: nameDatabseSpecified,
    description: description
  };
  return await axios.get(API_URL+"/"+serversId+"?Id="+id, headerToken).then(
    async (responseGetter) => {
        if (responseGetter.status == 404) {
          return await axios.post(API_URL , model, headerToken
            ).then(
            (response) => {
                if (response.status == 200) {
                }
                return response.data;
            }
          );
        }else{
          return await axios.post(API_URL+"/Put/"+serversId+"?Id="+id , model, headerToken).then(
            (response) => {
                if (response.status == 200) {
                }
                return response.data;
            }
          );
        }
    }
  );
};
const Delete = async (id,idServer) => {
  return await axios.post(API_URL+"/Delete/"+idServer+"?Id="+id ,{},  headerToken).then(
      (response) => {
          if (response.status == 200) {

          }
          return response.data;
      }
  );
};


const ServerDataBasesService = {
  gets,
  CreateOrUpdate,
  Delete
};

export default ServerDataBasesService;
