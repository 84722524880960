import axios from "axios";
import AuthServiceUser from "./AuthServiceUser";
import config from '../config';
const API_URL = config.domaineName+"/api/ShellScripts";
const userId = config.decodeToken.userId;
const token =AuthServiceUser.getCurrentUser();
const headerToken = {
  headers: {
    'Authorization': `Bearer ${token}` 
  }
};
const gets = async (id) => {
  if(id!=undefined){
    return await axios.get(API_URL+"/"+id, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }else{
    return await axios.get(API_URL, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }

};

const CreateOrUpdate = async (id, repeatFreq,execTime,execDays,libelle,description,notifyOnFail,enabled,scripttext) => {
  const model = {
    Id: id,
    RepeatFreqId: repeatFreq,
    ExecTime: execTime,
    ExecDays: execDays.toString(),
    Enabled: enabled,
    Libelle: libelle,
    Description: description,
    NotifyOnFail: notifyOnFail,
    Scripttext: scripttext,
    RepeatFreq:null,
    CreatedById:userId
  };
  if(id==0 ||id=="" || id==undefined || id==null){
    return await axios.post(API_URL , model, headerToken
      ).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }else{
    return await axios.put(API_URL+"/"+id , model, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }
};
const Delete = async (id) => {
  return await axios.delete(API_URL+"/"+id ,  headerToken).then(
      (response) => {
          if (response.status == 200) {

          }
          return response.data;
      }
  );
};


const ShellScriptsService = {
  gets,
  CreateOrUpdate,
  Delete
};

export default ShellScriptsService;
