import axios from "axios";
import AuthServiceUser from "./AuthServiceUser";
import config from '../config';
const API_URL = config.domaineName+"/api/RestoreDbs";
const userId = config.decodeToken.userId;
const token =AuthServiceUser.getCurrentUser();
const headerToken = {
  headers: {
    'Authorization': `Bearer ${token}` 
  }
};
const getRestoreDb = async (id) => {
  if(id!=undefined){
    return await axios.get(API_URL+"/"+id, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }else{
    return await axios.get(API_URL, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }

};

const ExcuteRequstSQlgetRestoreDb = async (id,name) => {
  const date = new Date();
  const formattedDate = date.toLocaleString('fr-FR', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'Africa/Tunis'
  }).replace(/,/g, '').replace(/\//g, '_').replace(/:/g, '_').replace(/ /g, 'T');


  return await axios.get(API_URL+"/ExcuteRequstSQlRestore?idbackup="+id , {
    headers: {
      'Authorization': `Bearer ${token}` 
    },
    responseType: 'blob'
  }).then(
      (response) => {
          if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name+formattedDate+'.bak');
            document.body.appendChild(link);
            link.click();
          }
          return response.data;
      }
    );
};
const DeleteRestoreDb = async (id) => {
  return await axios.delete(API_URL+"/"+id ,  headerToken).then(
      (response) => {
          if (response.status == 200) {

          }
          return response.data;
      }
  );
};


const ExcuteRequstSQlTranferDatabase = async (idserv , arr) => {

  const model = {
    lsValues: arr,
    requst: idserv
  };
  return await axios.post(API_URL+"/ExcuteRequstSQlTranferDatabase",model , {
    headers: {
      'Authorization': `Bearer ${token}` 
    },
    responseType: 'blob'
  }).then(
      (response) => {
          if (response.status == 200) {
            console.log("success");
          }
          return response.data;
      }
    );
};
const RestoreDbService = {
  getRestoreDb,
  DeleteRestoreDb,
  ExcuteRequstSQlgetRestoreDb,
  ExcuteRequstSQlTranferDatabase
};

export default RestoreDbService;
