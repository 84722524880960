import axios from "axios";
import AuthServiceUser from "./AuthServiceUser";
import config from '../config';
const API_URL = config.domaineName+"/api/BackUpDbs";
const userId = config.decodeToken.userId;
const token =AuthServiceUser.getCurrentUser();
const headerToken = {
  headers: {
    'Authorization': `Bearer ${token}` 
  }
};
const getBackUp = async (id) => {
  if(id!=undefined){
    return await axios.get(API_URL+"/"+id, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }else{
    return await axios.get(API_URL, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }

};
const getBackUpBYServerIdAndDatabase = async (serverid,database) => {
  if(serverid!=undefined && serverid!=undefined ){
    return await axios.get(API_URL+"/GetsByServerIdAndDatabase?serverid="+serverid+"&database="+database, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }

};

const CreateOrUpdateBackUpDb = async (id, serversId,nameDataBase,description,saUserName,saPassword) => {
  const model = {
    serversId: serversId,
    nameDataBase: nameDataBase,
    description: description,
    results: "",
    CreatedById:userId
  };
  if(id==0 ||id=="" || id==undefined || id==null){
    return await axios.post(API_URL , model, headerToken
      ).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }else{
    return await axios.put(API_URL+"/"+id , model, headerToken).then(
      (response) => {
          if (response.status == 200) {
          }
          return response.data;
      }
    );
  }
};
const ExcuteRequstSQlBackUp = async (database) => {
  const date = new Date();
  const formattedDate = date.toLocaleString('fr-FR', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'Africa/Tunis'
  }).replace(/,/g, '').replace(/\//g, '_').replace(/:/g, '_').replace(/ /g, 'T');

  const model = {
    lsValues: [database],
    requst: ""
  };
  var arrIds = database.split("$&-&$");
  return await axios.post(API_URL+"/ExcuteRequstSQlBackUp",model , {
    headers: {
      'Authorization': `Bearer ${token}` 
    },
    responseType: 'blob'
  }).then(
      async (response) => {
          if (response.status == 200) {
            await downloadFile(response.data,arrIds[1]+formattedDate+'.bak');
            console.log("success Download");

          }
          return response.data;
      }
    );
};
function downloadFile(data,filename) {
  return new Promise((resolve, reject) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename );
    document.body.appendChild(link);

    link.addEventListener('click', () => {
      resolve();
    });

    link.click();
  });
}
const DownloadBackUpById = async (id) => {
  if(id!=undefined){
    await axios.get(API_URL+"/"+id, headerToken).then(
      async (response) => {
          if (response.status == 200) {
            await axios.get(API_URL+"/DownloadBackUpById?id="+id , {
              headers: {
                'Authorization': `Bearer ${token}` 
              },
              responseType: 'blob'
            }).then(
                async (responseBlob) => {
                    if (responseBlob.status == 200) {
                      const date = new Date(response.data.createdAt);
                      const formattedDate = date.toLocaleString('fr-FR', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                        timeZone: 'Africa/Tunis'
                      }).replace(/,/g, '').replace(/\//g, '_').replace(/:/g, '_').replace(/ /g, 'T');

                      await downloadFile(response.data, response.data.nameDataBase+formattedDate+'.bak');
                      console.log("success Download");

                      // const url = window.URL.createObjectURL(new Blob([responseBlob.data]));
                      // const link = document.createElement('a');
                      // link.href = url;
                      // link.setAttribute('download', response.data.nameDataBase+formattedDate+'.bak');
                      // document.body.appendChild(link);
                      // link.click();
                    }
                    return responseBlob.data;
                }
              );
          }
          return response.data;
      }
    );
  }
  
};
const DeleteBackUp = async (id) => {
  return await axios.delete(API_URL+"/"+id ,  headerToken).then(
      (response) => {
          if (response.status == 200) {

          }
          return response.data;
      }
  );
};


const BackUpService = {
  getBackUp,
  CreateOrUpdateBackUpDb,
  DeleteBackUp,
  ExcuteRequstSQlBackUp,
  getBackUpBYServerIdAndDatabase,
  DownloadBackUpById
};

export default BackUpService;
