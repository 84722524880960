// material-ui
import { Checkbox, FormControl, Grid, FormControlLabel, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
// project imports
import { Formik } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Stack } from '@mui/system';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import ServerService from 'services/server.service';
import EditIcon from '@mui/icons-material/Edit';
// ==============================|| SAMPLE PAGE ||============================== //

const FormServer = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [initData, setInitData] = useState(props.getDataInfo);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {

        ServerService.CreateOrUpdateServers(values.id, values.hostName, values.port, values.defaultCatalogue, values.saUserName, values.saPassword, values.nameServer).then(
            (succ) => {
                // setInitData(values); 
                let succssedInfo = {
                    id: succ.id,
                    name: <b>{succ.hostName + ',' + succ.port}</b>,
                    actions: <div key={"DivActionServer" + succ.id}>
                        <IconButton aria-label="edit" size="small" onClick={(event) => setModalShow(event, succ.id)} >
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                    </div>,
                    children: [],
                }
                props.onClose();

            },
            (err) => { console.log(err); }
        );
    }

    useEffect(() => {

        if (props.id != null) {
            setInitData(props.getDataInfo);
        }
    }, [initData]);
    return (
        <Formik
            initialValues={initData}
            validationSchema={Yup.object().shape({
                hostName: Yup.string().max(255).required('hostName is required'),
                port: Yup.number().required('hostName is required'),
                defaultCatalogue: Yup.string().max(255).required('defaultCatalogue is required'),
                saUserName: Yup.string().max(255).required('Sa User Name is required')
            })}
            onSubmit={handleSubmit}

        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit} >
                    <Grid container spacing={matchDownSM ? 0 : 2} mt={2}>
                        <Grid item xs={12} sm={6} >
                            <Grid container spacing={matchDownSM ? 0 : 2} >

                                <Grid item xs={12} sm={4} >
                                    <FormControl fullWidth error={Boolean(touched.nameServer && errors.nameServer)} >
                                        <InputLabel htmlFor="outlined-hostName-login">Nom du Serveur</InputLabel>
                                        <OutlinedInput
                                            id="outlined-hostName-login"
                                            type="text"
                                            value={values.nameServer}
                                            name="nameServer"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Nom du Serveur"
                                            inputProps={{}}
                                        />
                                        {touched.nameServer && errors.nameServer && (
                                            <FormHelperText error id="standard-weight-helper-text-hostName-login">
                                                {errors.nameServer}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} >
                                    <FormControl fullWidth error={Boolean(touched.hostName && errors.hostName)} >
                                        <InputLabel htmlFor="outlined-hostName-login">Host Name</InputLabel>
                                        <OutlinedInput
                                            id="outlined-hostName-login"
                                            type="text"
                                            value={values.hostName}
                                            name="hostName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Host Name"
                                            inputProps={{}}
                                        />
                                        {touched.hostName && errors.hostName && (
                                            <FormHelperText error id="standard-weight-helper-text-hostName-login">
                                                {errors.hostName}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} >
                                    <FormControl fullWidth error={Boolean(touched.port && errors.port)} >
                                        <InputLabel htmlFor="outlined-port-login">Port</InputLabel>
                                        <OutlinedInput
                                            id="outlined-port-login"
                                            type="number"
                                            value={values.port}
                                            name="port"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Port"
                                            inputProps={{}}
                                        />
                                        {touched.port && errors.port && (
                                            <FormHelperText error id="standard-weight-helper-text-port-login">
                                                {errors.port}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <FormControl fullWidth error={Boolean(touched.defaultCatalogue && errors.defaultCatalogue)} >
                                <InputLabel htmlFor="outlined-defaultCatalogue-login">DataBase</InputLabel>
                                <OutlinedInput
                                    id="outlined-defaultCatalogue-login"
                                    type="text"
                                    value={values.defaultCatalogue}
                                    name="defaultCatalogue"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="DataBase"
                                    inputProps={{}}
                                />
                                {touched.defaultCatalogue && errors.defaultCatalogue && (
                                    <FormHelperText error id="standard-weight-helper-text-defaultCatalogue-login">
                                        {errors.defaultCatalogue}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <FormControl fullWidth error={Boolean(touched.saUserName && errors.saUserName)} >
                                <InputLabel htmlFor="outlined-saUserName-login">User Name</InputLabel>
                                <OutlinedInput
                                    id="outlined-saUserName-login"
                                    type="text"
                                    value={values.saUserName}
                                    name="saUserName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Username"
                                    inputProps={{}}
                                />
                                {touched.saUserName && errors.saUserName && (
                                    <FormHelperText error id="standard-weight-helper-text-saUserName-login">
                                        {errors.saUserName}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <FormControl
                                fullWidth
                                error={Boolean(touched.saPassword && errors.saPassword)}
                            >
                                <InputLabel htmlFor="outlined-saPassword-login">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-saPassword-login"
                                    type={showPassword ? 'text' : 'password'}
                                    value={values.saPassword}
                                    name="saPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                size="large"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    inputProps={{}}
                                />
                                {touched.saPassword && errors.saPassword && (
                                    <FormHelperText error id="standard-weight-helper-text-password-login">
                                        {errors.saPassword}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                    )}
                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                            >
                                Submit
                            </Button>
                        </AnimateButton>
                    </Box>
                </form>
            )}

        </Formik>


    );
};

export default FormServer;
