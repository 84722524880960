import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useEffect } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullWidthDialog( props  ) {
  const [open, setOpen] = React.useState(props.openClose);
  const [componentGetted, setComponentGetted] = React.useState(<div></div>);


  const handleClose = () => {
    props.setDialogFullWith({
      openClose:false,
      component:<div></div>
    });
  };
  useEffect(() => {
  },[]);
  return (
    <div>

      <Dialog open={props.openClose} onClose={handleClose}>
            <DialogTitle></DialogTitle>
            <DialogContent>
                <DialogContentText>  </DialogContentText>
                {props.component}
            </DialogContent>
            
            <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
      </Dialog>

    </div>
  );
}
