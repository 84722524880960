import * as React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import { ButtonGroup, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
export default function DialogBackups(props) {
    const { actionAdd, nameoflist, selectedValue, open, options, keyoption } = props;


    return (
        <Dialog  maxWidth={'md'} open={open}>
            <DialogTitle>
                {nameoflist}
                <br></br>
                {actionAdd}
 
            </DialogTitle>

            <TableContainer component={Paper}>

                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                    <DescriptionIcon />
                                </Avatar>
                            </TableCell>
                            <TableCell align="left">Nom</TableCell>
                            <TableCell align="left">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {options.map((evn) => (
                            <TableRow
                                key={'ListItem' + evn.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left" component="th" scope="row">
                                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                        <DescriptionIcon />
                                    </Avatar>

                                </TableCell>
                                <TableCell align="left" component="th" scope="row">
                                    {evn.label}
                                </TableCell>
                                <TableCell align="left">
                                    {evn.action}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </Dialog>
    );
}

//  function DialogBackupsV1(props) {
//   const [open, setOpen] = useState(false);
//   const [selectedValue, setSelectedValue] = useState(emails[1]);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = (value) => {
//     setOpen(false);
//     setSelectedValue(value);
//   };

//   return (
//     <div>
//       <Button variant="outlined" onClick={handleClickOpen}>
//         {props.btnname}
//       </Button>
//       <SimpleDialog
//         keyOption={props.keyoption}
//         selectedValue={selectedValue}
//         open={open}
//         options={[
//             'username@gmail.com',
//             'user02@gmail.com'
//         ]}
//         onClose={handleClose}
//       />
//     </div>
//   );
// }