import React, { useState } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';

const CreateTableForm = (props) => {
  const [tableName, setTableName] = useState('');
  const [columns, setColumns] = useState([
    {
      name: 'Id',
      type: '',
      constraint: '',
      seedValueAndIncrementValue: '',
      referencedTable: '',
      referencedColumnName: '',
      onDelete: ''
    }
  ]);

  const handleTableNameChange = (event) => {
    setTableName(event.target.value);
  };

  const handleColumnNameChange = (index, event) => {
    const newColumns = [...columns];
    newColumns[index].name = event.target.value;
    setColumns(newColumns);
  };

  const handleColumnTypeChange = (index, event) => {
    const newColumns = [...columns];
    newColumns[index].type = event.target.value;
    setColumns(newColumns);
  };
  const handleColumnConstraintChange = (index, event) => {
    const newColumns = [...columns];
    newColumns[index].constraint = event.target.value;
    setColumns(newColumns);
  };

  const handleAddColumn = () => {
    const newColumns = [...columns];
    newColumns.push({ name: '', type: '' });
    setColumns(newColumns);
  };

  const handleRemoveColumn = (index) => {
    const newColumns = [...columns];
    newColumns.splice(index, 1);
    setColumns(newColumns);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const columnDefs = columns
      .map((column) => `${column.name} ${column.type} ${column.constraint == undefined ? "" : column.constraint}`)
      .join(', ');
    const sqlQuery = `CREATE TABLE ${tableName} (${columnDefs});`;
    let query = sqlQuery + ' \n ' + props.sqlCmmd;
    props.setSqlCmmd(query);

    // send the sqlQuery to your server to execute the SQL
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <FormControl>
          <InputLabel htmlFor="tableName">Table Name</InputLabel>
          <TextField
            id="tableName"
            value={tableName}
            onChange={handleTableNameChange}
            required
          />
          <Button type="button" onClick={handleAddColumn}>
            Add Column
          </Button>
        </FormControl>
      </div>
      <div>


        {columns.map((column, index) => (
          <div key={index}>
            <Grid container spacing={1} mb={1}>
              <Grid item>
                <FormControl>

                  <InputLabel htmlFor="column-select">Columns</InputLabel>
                  <TextField
                    id={`column-name-${index}`}
                    label="Column Name"
                    value={column.name}
                    onChange={(event) => handleColumnNameChange(index, event)}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Select
                  id={`column-type-${index}`}
                  value={column.type}
                  onChange={(event) => handleColumnTypeChange(index, event)}
                  required
                >
                  <MenuItem value="">-- Select Type --</MenuItem>
                  <MenuItem value="INT">INT</MenuItem>
                  <MenuItem value="uniqueidentifier">Uniqueidentifier</MenuItem>
                  <MenuItem value="VARCHAR(1000)">VARCHAR</MenuItem>
                  <MenuItem value="DATE">DATE</MenuItem>
                  {/* add more data types as needed */}
                </Select>

              </Grid>
              <Grid item>
                <Select
                  id={`column-constraint-${index}`}
                  value={column.constraint}
                  onChange={(event) => handleColumnConstraintChange(index, event)}
                >
                  <MenuItem value="">-- Select Constraint --</MenuItem>
                  <MenuItem value="PRIMARY KEY">PRIMARY KEY</MenuItem>
                  <MenuItem value="UNIQUE">UNIQUE</MenuItem>
                  {/* add more data types as needed */}
                </Select>
              </Grid>
              <Grid item>
                <Button type="button" onClick={() => handleRemoveColumn(index)}>
                  Remove
                </Button>
              </Grid>

            </Grid>




          </div>
        ))}

      </div>
      <Button type="submit" variant="contained" color="primary">
        Create Table
      </Button>
    </form>
  );
};

export default CreateTableForm;
