import axios from "axios";
import AuthServiceUser from "./AuthServiceUser";
import config from '../config';
const API_URL = config.domaineName+"/api/LogsServers";
const token =AuthServiceUser.getCurrentUser();
const headerToken = {
  headers: {
    'Authorization': `Bearer ${token}` 
  }
};
const gets = async () => {
  return await axios.get(API_URL, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );

};



const LogsServersService = {
  gets
};

export default LogsServersService;
