// material-ui

import { Alert, Button, Grid, IconButton, Input } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import ServerService from 'services/server.service';
import { useState, useEffect } from 'react';
import OneServers from 'services/oneServers.service';
import CustomizedTreeView from 'ui-component/TreeView/CustomizedTreeView';

import FullScreenDialog from 'ui-component/Modal/FullScreenDialog';
import StartEditButtonGrid from 'ui-component/DataGrid/StartEditButtonGrid';
import FullWidthDialog from 'ui-component/Modal/FullWidthDialog';
import FormServer from './FormServer';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FormAddDatabaseServer from './FormAddDatabaseServer';
import BackUpService from 'services/BackUpDb.service';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
// ==============================|| SAMPLE PAGE ||============================== //

const IndexServersPage = (props) => {
    const [treeChecked, setTreeChecked] = useState([]);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        id: 'root',
        name: 'Servers',
        actions: <div></div>,
        children: [],
    });
    const [dialogFullWith, setDialogFullWith] = useState({
        openClose: false,
        component: <div></div>
    });
    const [dialogFullScreen, setDialogFullScreen] = useState({
        openClose: false,
        component: <div></div>
    });


    const setModalShow = (event, id) => {
        var arrIds = id.split("$&-&$");
        if (arrIds.length == 1) {
            ServerService.getServers(id).then(
                (succ) => {
                    let infoDataGetted = {
                        id: succ.id,
                        hostName: succ.hostName,
                        port: succ.port,
                        defaultCatalogue: succ.defaultCatalogue,
                        saUserName: succ.saUserName,
                        saPassword: succ.saPassword
                    };
                    let infoDataBaseGetted = {
                        id: succ.id,
                        nameDatabase: ""
                    };
                    let mddl = {
                        openClose: true,
                        component: 
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <FormServer
                                    dataParent={data}
                                    setDataParent={setData}
                                    getDataInfo={infoDataGetted} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormAddDatabaseServer
                                    dataParent={data}
                                    setDataParent={setData}
                                    getDataInfo={infoDataBaseGetted} />
                            </Grid>
                        </Grid>
                            

                    };
                    setDialogFullWith(mddl);
                },
                (error) => {
                    console.log(error);
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(resMessage);
                }
            );
        }
    }
    const deleteRequest = (event, id) => {
        var arrIds = id.split("$&-&$");
        if (arrIds.length == 1) {
            ServerService.DeleteServers(id).then(
                (succ) => { console.log(succ); },
                (error) => {
                    console.log(error);
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(resMessage);
                }
            );
        } else if (arrIds.length == 2) {


        } else if (arrIds.length == 3) {

        }

    }
    const handleClickOpenDialgFullWidth = () => {
        let mddl = {
            openClose: true,
            component: <FormServer
                dataParent={data}
                setDataParent={setData}
                getDataInfo={{
                    id: "",
                    hostName: "localhost",
                    port: 0,
                    defaultCatalogue: "master",
                    saUserName: "sa",
                    saPassword: ""
                }} />
        };
        setDialogFullWith(mddl);

    };
    const handleClickOpenDialgFullScreen = (e, itm) => {
        let ArrId = itm.id.split("$&-&$");

        OneServers.GetAllDataByDatabaseAndTable(ArrId[0], ArrId[1], ArrId[2]).then(
            (succ) => {

                let rowss = succ.filter((row, idx) => { if (idx > 0) { return row } });
                let colss = succ[0];

                let mddl = {
                    openClose: true,
                    component: <StartEditButtonGrid idServer={itm.id} rowsDynmique={rowss} columnsDynmique={colss} />
                };
                setDialogFullScreen(mddl);
            },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        );

        // setOpen(true);
    };
    const handleChecked = (e) => {
        const { value, checked } = e.target;
        var arr = treeChecked;
        if (checked) {
            arr.push(value);
        } else {
            let index = arr.findIndex((a) => a == value);
            arr.splice(index, 1);
        }
        setTreeChecked(arr);
        localStorage.setItem('treeChecked', JSON.stringify(arr));
        props.setTreeChecked(arr);
        let ArrId = value.split("$&-&$");

        handleChecked.bind(this);

    }
    const handleSelect = async (event, nodeIds) => {

        if (nodeIds == "root") {
            return false;
        }
        let ArrId = nodeIds[0].split("$&-&$");
        if (ArrId.length == 1) {
            var idServ = ArrId[0];
            await OneServers.GetAllDataBases(idServ).then(
                (succ) => {

                    let dd = data.children.map((item) => {
                        if (item.id == idServ && item.children.length == 0) {
                            let chldrns = [];
                            succ.map((itm) => {
                                let mdl = {
                                    id: idServ + "$&-&$" + itm,
                                    name: <b>{itm}</b>,
                                    actions: <div key={"DivActionServer" + idServ + "$&-&$" + itm}>
                                        <Input
                                            key={"InputCheckBox" + idServ + "$&-&$" + itm}
                                            type="checkbox"
                                            name="treeChecked"
                                            data-itm={item}
                                            value={idServ + "$&-&$" + itm}
                                            data-name={'Database : ' + { itm } + ' Server : ' + item.hostName + ',' + item.port + ' UserName : ' + item.saUserName}
                                            id="flexCheckDefault"
                                            onChange={(e, itm) => handleChecked(e, itm)}
                                        />
                                        {/* <IconButton aria-label="delete">
                                                <DeleteIcon fontSize='small' />
                                            </IconButton> */}

                                    </div>,
                                    children: []
                                };
                                chldrns.push(mdl);
                            });
                            item.children = chldrns;
                        }
                        return item;
                    })
                    setData({
                        id: 'root',
                        name: 'Servers',
                        actions: <div></div>,
                        children: dd,
                    });

                },
                (err) => { console.log(err); }
            );
        } else if (ArrId.length == 2) {
            var idServ = ArrId[0];
            var database = ArrId[1];
            await OneServers.GetAllTablesByDatabase(idServ, database).then(
                (succ) => {

                    let dd = data.children.map((item) => {
                        if (item.id == idServ) {

                            item.children.map((itm) => {
                                if (itm.id == idServ + "$&-&$" + database) {
                                    let chldrns = [];
                                    succ.map((itmGetted) => {
                                        let mdl = {
                                            id: idServ + "$&-&$" + database + "$&-&$" + itmGetted,
                                            name: <b>
                                                {itmGetted}
                                            </b>,
                                            actions: <div key={"DivActionServer" + idServ + "$&-&$" + database + "$&-&$" + itmGetted}>
                                                <IconButton onClick={(e) => handleClickOpenDialgFullScreen(e, mdl)} size="small" aria-label="edit">
                                                    <EditIcon fontSize='small' />
                                                </IconButton>
                                            </div>,
                                            children: []
                                        };
                                        chldrns.push(mdl);

                                    });
                                    itm.children = chldrns;
                                }
                            });

                        }
                        return item;
                    })
                    setData({
                        id: 'root',
                        name: 'Servers',
                        actions: <div></div>,
                        children: dd,
                    });

                },
                (err) => { console.log(err); }
            );
        }

    };



    useEffect(() => {
        let servers = []
        if ((data.children.length == 0)) {
            ServerService.getServers().then(
                (ee) => {
                    ee.map(
                        (item) => {
                            servers.push({
                                id: item.id,
                                name: <b>{item.hostName + ',' + item.port}</b>,
                                actions: <div key={"DivActionServer" + item.id}>
                                    <IconButton aria-label="edit" size="small" onClick={(event) => setModalShow(event, item.id)} >
                                        <EditIcon fontSize="inherit" />
                                    </IconButton>
                                </div>,
                                children: [],
                            });
                        }
                    );
                    setData({
                        id: 'root',
                        name: 'Servers',
                        actions: <div></div>,
                        children: servers,
                    });
                },
                (error) => {
                    console.log(error);
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(resMessage);
                }
            );
            localStorage.setItem('treeChecked', JSON.stringify([]));
        }

    }, []);

    const getbackup = (curr) => {
        console.log(curr);
        event.preventDefault();
        setLoading(true);
        BackUpService.ExcuteRequstSQlBackUp(curr).then(
            (ee) => {  setLoading(false); },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        );
    }
    return (
        <Grid container >
            <Grid item xs={8}>
            <Button variant="outlined" onClick={(e) => handleClickOpenDialgFullWidth()} startIcon={<AddCircleOutlineIcon fontSize="inherit" />}>
                Créer une instance de machine virtuelle
            </Button>
            </Grid>
            <Grid item xs={8}>
                <CustomizedTreeView dataa={data} handleSelect={handleSelect} />
            </Grid>
            <FullWidthDialog setDialogFullWith={setDialogFullWith} openClose={dialogFullWith.openClose} component={dialogFullWith.component} />
            <FullScreenDialog setDialogFullScreen={setDialogFullScreen} openClose={dialogFullScreen.openClose} component={dialogFullScreen.component} />
        </Grid>
    );
};

export default IndexServersPage;
