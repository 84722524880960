import { Grid } from '@mui/material';
import config from 'config';
import { useEffect, useState } from 'react';


// ==============================|| DEFAULT DASHBOARD ||============================== //

const API_URL = config.domaineName + "/api/";
const DashboardGestDatabase = () => {


    useEffect(() => {

    }, []);

    return (
        <div>
        </div>
    );
};

export default DashboardGestDatabase;
