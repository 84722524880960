import axios from "axios";
import AuthServiceUser from "./AuthServiceUser";
import config from '../config';
const API_URL = config.domaineName+"/api/Accounts";
const token =AuthServiceUser.getCurrentUser();
const headerToken = {
  headers: {
    'Authorization': `Bearer ${token}` 
  }
};
const GetRoles = async () => {
  return await axios.get(API_URL+"/GetRoles", headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );

};
const GetAllUsers = async () => {
  return await axios.get(API_URL+"/GetAllUsers", headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );

};
const GetAllRolesUsers = async () => {
  return await axios.get(API_URL+"/GetAllRolesUsers", headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );

};
const AddRoles = async (nameRole) => {
  return await axios.post(API_URL+"/AddRoles",nameRole, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );

};

const UpdateRoles= async (idrole,nameRole) => {
  return await axios.put(API_URL+"/UpdateRoles​/"+idrole,nameRole, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );

};

const SetRoleUsers = async (userId,roleIds) => {

  return await axios.post(API_URL+"/SetRoleUsers/"+userId,roleIds, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );

};
const AddUser = async (user) => {
    const model = {
        nom: user.nom,
        city: user.city,
        email: user.email,
        dateOfBirth: user.dateOfBirth,
        password: user.password,
        confirmPassword: user.password,
        phoneNumber: user.phoneNumber
    };
  return await axios.post(`${API_URL}/AddUser`,model, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );

};

const UpdateUser = async (userId,user) => {
    const model = {
        nom: user.nom,
        city: user.city,
        email: user.email,
        dateOfBirth: user.dateOfBirth,
        password: "123456",
        confirmPassword: "123456",
        phoneNumber: user.phoneNumber
    };
  return await axios.put(`${API_URL}/UpdateUser/${userId}`,model, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );

};



const AccountService = {
    GetRoles,
    GetAllUsers,
    GetAllRolesUsers,
    AddRoles,
    UpdateRoles,
    SetRoleUsers,
    AddUser,
    UpdateUser
};

export default AccountService;
