import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { Alert } from '@mui/material';
import StartEditButtonGrid from 'ui-component/DataGrid/StartEditButtonGrid';
import { isEmptyArray } from 'formik';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export default function CustmizeTabs(props) {
    const [value, setValue] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [isLoding, setIsLoding] = React.useState(false);
  
    const handleChange = (event, newValue) => {
      
      setValue(newValue);
    };
    useEffect((effect,deps) => {
      setData(props.tabsContent);
      setIsLoding(true);
    },[props.tabsContent]);
    return (
      <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper'}}
      >
        <Tabs
          variant="scrollable"
          value={value}
          scrollButtons
          onChange={handleChange}
          aria-label="horizontal tabs example"
        >
          {data.map((b,idx)=>{
                  return (<Tab key={"TabTitle"+idx} label={b.database}  />);
              })}
          
        </Tabs>
        {data.map((b,idx)=>{
          
            let GridEditing = (<> </>);
            if(b.anydata!=null &&  isLoding){
              if(Object.keys(b.anydata[0]).length != 0 ){
                let succ = b.anydata;
                let rowss = succ.filter((row,idx) => {if(idx>0){ return row}});
                let colss = succ[0];
                let serverId = b.idServer + "$&-&$"+ b.database + "$&-&$" + b.tablename;
                GridEditing = (<StartEditButtonGrid 
                  key={"GridDataTable"+idx} 
                  idServer={serverId} 
                  rowsDynmique={rowss} 
                  columnsDynmique={colss} />  );
              }

            }
            return (<TabPanel key={"TabPanel"+idx} value={value} index={idx}>
              <Alert key={"Alerte"+idx} severity={b.icon} >{b.message}</Alert>
              {GridEditing}
          </TabPanel>);
          })}
  
      </Box>
    );
  }