import React from 'react';
import { Button, ButtonGroup, Collapse, Grid, IconButton, Input, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DynamiqueMenuButton from 'ui-component/Tabs/DynamiqueMenuButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box } from '@mui/system';
export default function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [rowLength, setRowLength] = useState(0);
    useEffect(() => {
        setRowLength(row.children.length);
      }, []);
    return (
        <React.Fragment key={'Row'+row.id +'$&-&$'+row.name}>
        <TableRow style={{ paddingBottom: 0, paddingTop: 0 }} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} component="th" scope="row">
        {rowLength ==0?(<></>):(
         <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
        >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        )}
 
        </TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} component="th"  scope="row">
            {row.check} {row.name} {row.dernierebackup}
        </TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} component="th" scope="row" align="left">{row.actions}</TableCell>
      </TableRow>
      {row.children.length ==0?(<></>):(
        <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
                {row.label}
            </Typography>
            <Table size="small" aria-label="purchases" >
                <TableHead>
                <TableRow>
                    <TableCell />
                    <TableCell>{row.labelColum}</TableCell>
                    <TableCell align="left">Action</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {row.children.map((item) => (
                    <Row key={'Row'+item.id}  row={item} />
                ))}
                </TableBody>
            </Table>
            </Box>
        </Collapse>
        </TableCell>
        </TableRow>
      )}
      </React.Fragment>
    );
  }