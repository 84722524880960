// material-ui
import { AspectRatio } from '@mui/icons-material';
import { Grid, Input } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useState } from 'react';
import { gridSpacing } from 'store/constant';
import FullScreenDialog from 'ui-component/Modal/FullScreenDialog';
import MainCard from 'ui-component/cards/MainCard';
// project imports
import IndexServersPage from 'views/crud-servers';
import FormRequst from 'views/crud-servers/FormRequst';

// ==============================|| SAMPLE PAGE ||============================== //

const ServersPage = () => {
        const [isLoading, setLoading] = useState(true);
        const [treeChecked, setTreeChecked] = useState();
        useEffect(() => {
            setLoading(false);
        }, []);
    
        return (
           <MainCard>
                <Grid
                    variant="outlined"
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                 >
                    <Grid item >
                        <IndexServersPage treeChecked={treeChecked} setTreeChecked={setTreeChecked} isLoading={isLoading} />
                    </Grid>
                    <Grid item >
                            <FormRequst treeChecked={treeChecked} />
                        </Grid>
                    
                </Grid>
           </MainCard>

        );
};

export default ServersPage;
