import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ServersPage from 'views/pages/servers-page';
import UrlInvocationsPage from 'views/pages/UrlInvocations-page';
import SqlCmdsPage from 'views/pages/SqlCmds-page';
import ShellScriptsPage from 'views/pages/ShellScripts-page';
import InstancesPage from 'views/pages/Instances-page';
import DashboardGestDatabase from 'views/dashboard/GestDatabase';
import MonitoringDatabase from 'views/dashboard/Monitoring';
import DashboardSuperViseur from 'views/dashboard/SuperViseur';

const FormRequst = Loadable(lazy(() => import('views/crud-servers/FormRequst')));
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// ==============================|| MAIN ROUTING ||============================== //

const MainHeaderRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            text:"Gestion des bases de données",
            element: (<MainLayout idurlSideBar={"gestdatabase"}  custmSideBar={true} showingSideBar={false} />),
            children: [
                {
                    path: '',
                    element: <InstancesPage />
                }
            ]
        },
        {
            path: '/gestdatabase',
            text:"Gestion des bases de données",
            element: (<MainLayout idurlSideBar={"gestdatabase"} custmSideBar={true} showingSideBar={false} />),
            children: [
                {
                    path: '',
                    element: <InstancesPage />
                }
            ]

        },
        {
            path: '/supervision',
            text:"Gestion et supervision des actions planifiés",
            element: (<MainLayout idurlSideBar={"supervision"} custmSideBar={false} showingSideBar={true}  />),
            children: [
                {
                    path: '',
                    element: <DashboardSuperViseur />
                },
                // {
                //     path: 'servers-page',
                //     element: <InstancesPage />
                // },
                {
                    path: 'ShellScripts-page',
                    element: <ShellScriptsPage />
                },
                {
                    path: 'sqlCmds-page',
                    element: <SqlCmdsPage />
                },
                {
                    path: 'UrlInvocations-page',
                    element: <UrlInvocationsPage />
                }
            ]
        },
        {
            path: 'https://mts-monotoring.timesheet.tn/d/TocpL9Lizd/prometheus-mssql-exporter?orgId=1&refresh=5s&var-datasource=Prometheus&var-job=sqltwo&var-instance=prometheus-mssql-exporter-dbsqltwo:4000&var-database_name=TimeSheetManagement&from=now-5m&to=now',
            text:"Surveillance des serveurs",
            element: (<MainLayout idurlSideBar={"monitoring"}  custmSideBar={false} showingSideBar={true}  />),
            children: [
                {
                    path: '',
                    element: <MonitoringDatabase />
                    // element: <DashboardDefault />
                }
            ]
        }
    ]
};

export default MainHeaderRoutes;
