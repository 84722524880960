import { Grid } from '@mui/material';
import config from 'config';
import { useEffect, useState } from 'react';
import React from 'react';
// import { GrafanaTheme2, ThemeContext2 } from '@grafana/ui';
// ==============================|| DEFAULT DASHBOARD ||============================== //

// const API_URL = config.domaineName + "/api/";
const MonitoringDatabase = () => {

    const iframeUrl = 'https://mts-monotoring.timesheet.tn/d/TocpL9Lizd/prometheus-mssql-exporter?orgId=1&refresh=5s&var-datasource=Prometheus&var-job=sqltwo&var-instance=prometheus-mssql-exporter-dbsqltwo:4000&var-database_name=TimeSheetManagement&from=now-5m&to=now';
    // const iframeUrl = 'http://localhost:8083/d/9J6ObCEMz/microsoft-sql-server?orgId=1';

    return (
        <iframe
            src={iframeUrl}
            title="My Grafana Dashboard"
            width="100%"
            height="800px"
            frameBorder="0"
        />
    );
};

export default MonitoringDatabase;
