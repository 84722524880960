// material-ui
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TablePagination, TableRow, Toolbar, Tooltip, Typography, useMediaQuery
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useState } from 'react';
import { gridSpacing } from 'store/constant';

import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import MainCard from 'ui-component/cards/MainCard';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@emotion/react';
import RepeatFreqService from 'services/RepeatFreq.service';
import UrlInvocationsService from 'services/UrlInvocations.service';
import FormUrlInvocations from './FormUrlInvocations';

const UrlInvocationsPage = () => {
    const [isLoading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [rowsSelected, setRowsSelected] = useState([]);
    const [reloadForce, setRreloadForce] = useState(true);
    const [rowsSelectedLength, setRowsSelectedLength] = useState(0);
    const [error, setError] = useState(null);
    const [openDialogue, setopenDialogue] = useState(true);
    const [htmlSetter, setHtmlSetter] = useState(<></>);
    const [optionsReapat, setOptionsReapat] = useState([]);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();

    }, [reloadForce]);
    // Define functions to handle CRUD operations
    const fetchData = async () => {
        try {
            await RepeatFreqService.gets().then(
                (succrepeat) => {
                    setOptionsReapat(succrepeat);
                },
                (errrepaet) => { console.log(errrepaet); }
            );
            await UrlInvocationsService.gets().then(
                (succ) => {
                    setRows(succ);
                    setLoading(false);
                },
                (err) => { console.log(err); }
            );


        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }
    const handleClose = () => {
        setHtmlSetter(<></>);
    };
    const handleCreateUpdateForm = (id) => {
        setHtmlSetter(
            <Dialog fullScreen open={openDialogue} onClose={handleClose}>
                <DialogTitle>
                    Nouveau Url Invocations
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <DisabledByDefaultIcon />
                        </IconButton>
                    </DialogContentText>
                    <FormUrlInvocations idform={id} onClose={BtnActualiser} />
                </DialogContent>
            </Dialog>
        );
    };
    const handleDelete = () => {
        setHtmlSetter(
            <Dialog open={openDialogue} onClose={handleClose}>
                <DialogTitle>
                    Supprimer Url Invocations
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <DisabledByDefaultIcon />
                        </IconButton>
                        Êtes-vous sûr de vouloir supprimer cette ligne de tableau ?

                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        rowsSelected.map(async (itm) => {
                            await UrlInvocationsService.Delete(itm).then(
                                (succ) => {
                                    console.log(succ);
                                },
                                (err) => { console.log(err); }
                            );
                        })
                        setRowsSelected([]);
                        setRowsSelectedLength(0);
                        BtnActualiser();

                    }}
                    >
                        Oui
                    </Button>
                    <Button onClick={handleClose} >
                        Non
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
    const BtnActualiser = async () => {
        setLoading(true);
        setHtmlSetter(<></>);
        await fetchData();
        setRreloadForce(!reloadForce);
        setLoading(false);

    };
    const ShowingDesc = async (title, text) => {
        setHtmlSetter(
            <Dialog open={openDialogue} onClose={handleClose}>
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <DisabledByDefaultIcon />
                        </IconButton>


                    </DialogContentText>
                    {text}
                </DialogContent>
            </Dialog>
        );
    };
    return (
        <MainCard>
            <Grid
                variant="outlined"
                sx={{
                    display: 'flex',
                    gap: 2,
                    p: 2,
                    minWidth: 300,
                    borderRadius: 'sm',
                }}
                container spacing={gridSpacing}>
                <Box sx={{ width: '100%' }}>


                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 }
                            }}
                        >

                            {rowsSelectedLength > 0 ? (
                                <Typography
                                    sx={{ flex: '1 1 100%' }}
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                >
                                    {rowsSelectedLength} selected
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{ flex: '1 1 100%' }}
                                    variant="h6"
                                    id="tableTitle"
                                    component="div"
                                >
                                    Url Invocations
                                </Typography>
                            )}
                            <Button size="small" variant="contained" type='submit' onClick={() => handleCreateUpdateForm(undefined)} startIcon={<AddCircleIcon />}>
                                Création
                            </Button>
                            {rowsSelectedLength > 0 ? (
                                <Tooltip title="Delete">
                                    <Button size="small" variant="contained" type='submit' onClick={() => handleDelete()}  >
                                        Supprimer
                                    </Button>
                                </Tooltip>
                            ) : (
                                <LoadingButton
                                    size="small"
                                    onClick={(e) => BtnActualiser()}
                                    loading={isLoading}
                                    loadingPosition="start"
                                    startIcon={<></>}
                                    variant="contained"
                                >
                                    <span>Actualiser</span>
                                </LoadingButton>
                            )}
                        </Toolbar>
                        <TableContainer>
                            {isLoading ?
                                (<></>) :
                                (

                                    <Table
                                        stickyHeader
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={'small'}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell >
                                                    <input
                                                        // indeterminate={numSelected > 0 && numSelected < rowCount}
                                                        // checked={rowCount > 0 && numSelected === rowCount}
                                                        type='checkbox'
                                                        onChange={(event) => {
                                                            const { checked } = event.target;
                                                            var rowsselect = rowsSelected;
                                                            const checkboxes = document.querySelectorAll('input[data-checkbox="checkedrow"]');
                                                            checkboxes.forEach((checkbox) => {
                                                                checkbox.checked = checked;
                                                            });
                                                            if (checked) {

                                                                rows.map((row) => {
                                                                    let index = rowsselect.findIndex((a) => a == row.id);

                                                                    if (index == -1) {
                                                                        rowsselect.push(row.id);
                                                                    }

                                                                })
                                                            } else {
                                                                rows.map((row) => {
                                                                    let index = rowsselect.findIndex((a) => a == row.id);

                                                                    if (index != -1) {

                                                                        rowsselect.splice(index, 1);
                                                                    }

                                                                })

                                                            }
                                                            setRowsSelected(rowsselect);
                                                            setRowsSelectedLength(rowsselect.length);
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell align="left">Temps</TableCell>
                                                <TableCell align="left">URL</TableCell>
                                                <TableCell align="left">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => {

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={'TableRow' + row.id}
                                                        // selected={isItemSelected}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell >
                                                            <input
                                                                key={'CheckboxRow' + row.id}
                                                                color="primary"
                                                                type='checkbox'
                                                                data-checkbox="checkedrow"
                                                                onChange={(event) => {
                                                                    const { checked } = event.target;
                                                                    var rowsselect = rowsSelected;
                                                                    let index = rowsselect.findIndex((a) => a == row.id);
                                                                    if (checked) {
                                                                        if (index == -1) {
                                                                            rowsselect.push(row.id);
                                                                        }
                                                                    } else {
                                                                        if (index != -1) {
                                                                            rowsselect.splice(index, 1);
                                                                        }
                                                                    }
                                                                    setRowsSelected(rowsselect);
                                                                    setRowsSelectedLength(rowsselect.length);
                                                                }}
                                                                id={'CheckboxRow' + row.id}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left" >
                                                            Libelle : {row.libelle} <br></br>
                                                            Description : {row.description} <br></br>
                                                        </TableCell>
                                                        <TableCell align="left" >
                                                            {optionsReapat[row.repeatFreqId].label} <br></br>
                                                            {row.execDays} {row.execTime} <br></br>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <LoadingButton
                                                                size="small"
                                                                onClick={(e) => ShowingDesc(row.libelle, row.urLtext)}
                                                                loading={false}
                                                                loadingPosition="start"
                                                                startIcon={<></>}
                                                                variant="contained"
                                                            >
                                                                <span>Afficher</span>
                                                            </LoadingButton>
                                                        </TableCell>
                                                        <TableCell align="left" >
                                                            Notify On Fail :
                                                            <input
                                                                key={'CheckboxNotifyOnFail' + row.id}
                                                                color="primary"
                                                                checked={row.notifyOnFail}
                                                                value={row.notifyOnFail}
                                                                type='checkbox'
                                                                onChange={(event) => {
                                                                    const { checked } = event.target;
                                                                    console.log('CheckboxNotifyOnFail' + row.id + ' => ' + checked);

                                                                }}
                                                                id={'CheckboxNotifyOnFail' + row.id}
                                                            />  <br></br>
                                                            Enabled :
                                                            <input
                                                                key={'CheckboxEnabled' + row.id}
                                                                color="primary"
                                                                checked={row.enabled}
                                                                value={row.enabled}
                                                                type='checkbox'
                                                                onChange={(event) => {
                                                                    const { checked } = event.target;
                                                                    console.log('CheckboxEnabled' + row.id + ' => ' + checked);
                                                                }}
                                                                id={'CheckboxEnabled' + row.id}
                                                            /> <br></br>
                                                            <IconButton key={'BtnEdit' + row.id} aria-label="edit" size="small" onClick={() => handleCreateUpdateForm(row.id)} >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                            <br></br>
                                                        </TableCell>

                                                    </TableRow>

                                                );
                                            })}

                                        </TableBody>
                                    </Table>
                                )

                            }
                        </TableContainer>
                    </Paper>

                </Box>
                {htmlSetter}


            </Grid>
        </MainCard>

    );
};

export default UrlInvocationsPage;
