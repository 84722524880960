import { useState } from "react";
import OneServers from "services/oneServers.service";

const { Button, Backdrop, CircularProgress } = require("@mui/material");

export default function BackupForm(props) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [openLoading, setOpenLoading] = useState(false);


    function handleFileSelect(event) {
      setSelectedFile(event.target.files[0]);
    }
  
    async function handleFormSubmit(event) {
      event.preventDefault();
      setOpenLoading(true);
      const formData = new FormData();
      formData.append('backupFile', selectedFile);
      const response = await OneServers.UploadDataBases(props.ServerId,formData);
      console.log(response);
      setOpenLoading(false);
      if (response.ok) {
        alert('Backup uploaded successfully.');
      } else {
        alert('Backup upload failed.');
      }
      
      
    }
  
    return (
      <>
      
      <form onSubmit={handleFormSubmit}>
        <label>
          Select backup file:
          <Button variant="contained" component="label">
                Upload
                <input hidden onChange={handleFileSelect} type="file" />
            </Button>
          {/* <input type="file" onChange={handleFileSelect} /> */}
        </label><br></br>
        <button className="btnSubmitSpecfique" type="submit">Upload</button>

      </form>
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoading}
                onClick={()=>{ console.log("Backdrop Clicked");}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
      
      </>

    );
  }
  