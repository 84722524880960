import axios from "axios";
import AuthServiceUser from "./AuthServiceUser";
import config from '../config';
const API_URL = config.domaineName+ "/OneServers";
const userId = config.decodeToken.userId;
const token =AuthServiceUser.getCurrentUser();
const headerToken = {
  headers: {
    'Authorization': `Bearer ${token}` 
  }
};
const GetAllDataBases = (id) => {
  var pwd = config.treeServerSepcified.find((a) => a.id == id).pwd;
  const model = {
    password: pwd
  };
  return axios.post(API_URL+"/GetAllDataBases/"+id,model, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );
};
const AddDataBases = (id,nameDatabase) => {
  var pwd = config.treeServerSepcified.find((a) => a.id == id).pwd;
  const model = {
    password: pwd,
    nameDatabase:nameDatabase
  };
  return axios.post(API_URL+"/AddDataBases/"+id,model, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );
};
const UploadDataBases = async (id,formData) => {
  
  const response = await fetch(API_URL+"/UploadDataBases/"+id,  {
    method: 'POST',
    body: formData,
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  });
  return response;
};
const DeleteDataBases = (id,nameDatabase) => {
  var pwd = config.treeServerSepcified.find((a) => a.id == id).pwd;
  const model = {
    password: pwd,
    nameDatabase:nameDatabase
  };
  return axios.post(API_URL+"/DeleteDataBases/"+id,model, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );
};
const GetAllTablesByDatabase = (id,database) => {
  var pwd = config.treeServerSepcified.find((a) => a.id == id).pwd;
  const model = {
    password: pwd,
    database:database
  };
  return axios.post(API_URL+"/GetAllTablesByDatabase/"+id,model, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );
};

const GetAllDataByDatabaseAndTable = (id,database,table) => {
  var pwd = config.treeServerSepcified.find((a) => a.id == id).pwd;
  const model = {
    password: pwd,
    database:database,
    table : table,
    pageSize : 100,
    rowDebit : 0
  };
  return axios.post(API_URL+"/GetAllDataByDatabaseAndTable/"+id,model, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );
};
const GetAllColumnsByTables = (id,database,table) => {
  var pwd = config.treeServerSepcified.find((a) => a.id == id).pwd;
  const model = {
    password: pwd,
    database:database,
    table : table
  };
  return axios.post(API_URL+"/GetAllColumnsByTables/"+id,model, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );
};

const UpdateDataByDatabaseAndTable = (id,database,table,setters,condition) => {
  var pwd = config.treeServerSepcified.find((a) => a.id == id).pwd;
  const model = {
    password: pwd,
    database:database,
    table : table,
    setters:setters,
    condition : condition
  };
  return axios.post(API_URL+"/UpdateDataByDatabaseAndTable/"+id,model, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );
};
const AddDataByDatabaseAndTableAsync = (id,database,table,setters,condition) => {
  var pwd = config.treeServerSepcified.find((a) => a.id == id).pwd;
  const model = {
    password: pwd,
    database:database,
    table : table,
    setters:setters,
    condition : condition
  };
  return axios.post(API_URL+"/AddDataByDatabaseAndTableAsync/"+id,model, headerToken).then(
    (response) => {
        if (response.status == 200) {
        }
        return response.data;
    }
  );
};

const OneServers = {
  GetAllDataBases,
  AddDataBases,
  UploadDataBases,
  DeleteDataBases,
  GetAllTablesByDatabase,
  DeleteDataBases,
  GetAllDataByDatabaseAndTable,
  UpdateDataByDatabaseAndTable,
  GetAllColumnsByTables,
  AddDataByDatabaseAndTableAsync
};

export default OneServers;
