// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useState } from 'react';
import { useEffect } from 'react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ idurl }) => {
    const [idurlSideBar, setidurlSideBar] = useState("any");
    useEffect(() => {
        if(idurl!=undefined){
            setidurlSideBar(idurl);
        }
    }, [idurl]);
    const navItems = menuItem.items.map((item) => {
        if(item.id.includes(idurlSideBar) ){
            switch (item.type) {
                case 'group':
                    return <NavGroup idurl={idurlSideBar} key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        }
 
    });

    return <>{navItems}</>;
};

export default MenuList;
