import BackUpService from "services/BackUpDb.service";


const getbackup = async (curr) => {
    await BackUpService.ExcuteRequstSQlBackUp(curr).then(
        (ee) => { console.log(ee); },
        (error) => {
            console.log(error);
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            console.log(resMessage);
        }
    );
}

const InstanceService = {
    getbackup
  };
  
  export default InstanceService;
  