// material-ui
import {
      Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, useMediaQuery
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useState } from 'react';

import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@emotion/react';
import AccountService from 'services/account.service';
import useScriptRef from 'hooks/useScriptRef';
import config from 'config';
import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Link } from 'react-router-dom';
const ProfilePage = () => {
      const theme = useTheme();
      const scriptedRef = useScriptRef();
      const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
      const [showPassword, setShowPassword] = useState(false);
      const [checked, setChecked] = useState(true);
      const [isLoading, setIsLoading] = useState(false);

      const [strength, setStrength] = useState(0);
      const [level, setLevel] = useState();
      const [defaultValue, setDefaultValue] = useState({
            nom: '',
            city: '',
            email: '',
            dateOfBirth: "",
            phoneNumber: "",
            password: ''
      });
      const [tokenValue, setTokenValue] = useState({});


      const changePassword = (value) => {
            const temp = strengthIndicator(value);
            setStrength(temp);
            setLevel(strengthColor(temp));
      };

      const handleClickShowPassword = () => {
            setShowPassword(!showPassword);
      };

      const handleMouseDownPassword = (event) => {
            event.preventDefault();
      };

      useEffect(() => {
            setIsLoading(true);
      }, [defaultValue]);
      useEffect(() => {
            setIsLoading(false);
            var infoToken = config.decodeToken;
            setTokenValue(infoToken);
            let item = {
                  nom: infoToken.name == undefined ? "" : infoToken.name,
                  city: infoToken.city == undefined ? "" : infoToken.city,
                  email: infoToken.email == undefined ? "" : infoToken.email,
                  dateOfBirth: infoToken.dateofbirth == undefined ? "" : moment(infoToken.dateofbirth).format("YYYY-MM-DD"),
                  phoneNumber: infoToken.phone == undefined ? "" : infoToken.phone,
                  password: '123456'
            };
            setDefaultValue(item);


      }, []);


      const handleRegister = async (values, { setErrors, setStatus, setSubmitting }) => {

            try {
                  if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                        if (tokenValue.userId != undefined) {
                              AccountService.UpdateUser(tokenValue.userId, values).then(
                                    (succ) => {
                                          console.log(succ);
                                    },
                                    (error) => {
                                          console.log(error);
                                          const resMessage =
                                                (error.response &&
                                                      error.response.data &&
                                                      error.response.data.message) ||
                                                error.message ||
                                                error.toString();
                                          console.log(resMessage);
                                    }
                              );
                        } else {
                              AccountService.AddUser(values).then(
                                    (succ) => {
                                          console.log(succ);
                                    },
                                    (error) => {
                                          console.log(error);
                                          const resMessage =
                                                (error.response &&
                                                      error.response.data &&
                                                      error.response.data.message) ||
                                                error.message ||
                                                error.toString();
                                          console.log(resMessage);
                                    }
                              );
                        }

                  }
            } catch (err) {
                  console.error(err);
                  if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                  }
            }


      };
      return (
            <MainCard>
                  {!isLoading ? (<></>) : (


                        <Formik
                              initialValues={defaultValue}
                              validationSchema={Yup.object().shape({
                                    email: Yup.string().email('Doit être un email valide').max(255).required("L'e-mail est requis"),
                                    nom: Yup.string().max(255).required('Le nom est requis'),
                                    password: Yup.string().max(255).required('Password is required')
                              })}
                              onSubmit={handleRegister}

                        >
                              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                    <form noValidate onSubmit={handleSubmit} >
                                          <Grid container spacing={matchDownSM ? 0 : 2}>
                                                <Grid item xs={12} sm={6} >
                                                      <FormControl fullWidth error={Boolean(touched.nom && errors.nom)} sx={{ ...theme.typography.customInput }}>
                                                            <InputLabel htmlFor="outlined-adornment-nom-register">Nom</InputLabel>
                                                            <OutlinedInput
                                                                  id="outlined-adornment-nom-register"
                                                                  type="text"
                                                                  value={values.nom}
                                                                  name="nom"
                                                                  onBlur={handleBlur}
                                                                  onChange={handleChange}
                                                                  inputProps={{}}
                                                            />
                                                            {touched.nom && errors.nom && (
                                                                  <FormHelperText error id="standard-weight-helper-text--register">
                                                                        {errors.nom}
                                                                  </FormHelperText>
                                                            )}
                                                      </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} >
                                                      <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                                            <InputLabel htmlFor="outlined-adornment-city-register">Ville</InputLabel>
                                                            <OutlinedInput
                                                                  id="outlined-adornment-city-register"
                                                                  type="text"
                                                                  value={values.city}
                                                                  name="city"
                                                                  onBlur={handleBlur}
                                                                  onChange={handleChange}
                                                                  inputProps={{}}
                                                            />
                                                      </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} >
                                                      <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                                            <InputLabel htmlFor="outlined-adornment-dateOfBirth-register">Date de naissance</InputLabel>
                                                            <OutlinedInput
                                                                  id="outlined-adornment-dateOfBirth-register"
                                                                  type="date"
                                                                  value={values.dateOfBirth}
                                                                  name="dateOfBirth"
                                                                  onBlur={handleBlur}
                                                                  onChange={handleChange}
                                                                  inputProps={{}}
                                                            />
                                                      </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} >
                                                      <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                                            <InputLabel htmlFor="outlined-adornment-phoneNumber-register">Téléphone</InputLabel>
                                                            <OutlinedInput
                                                                  id="outlined-adornment-phoneNumber-register"
                                                                  type="tel"
                                                                  value={values.phoneNumber}
                                                                  name="phoneNumber"
                                                                  onBlur={handleBlur}
                                                                  onChange={handleChange}
                                                                  inputProps={{}}
                                                            />
                                                      </FormControl>
                                                </Grid>
                                          </Grid>
                                          <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                                                <InputLabel htmlFor="outlined-adornment-email-register">Adresse e-mail / Nom d'utilisateur</InputLabel>
                                                <OutlinedInput
                                                      id="outlined-adornment-email-register"
                                                      type="email"
                                                      value={values.email}
                                                      name="email"
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      inputProps={{}}
                                                />
                                                {touched.email && errors.email && (
                                                      <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.email}
                                                      </FormHelperText>
                                                )}
                                          </FormControl>
                                          {tokenValue.userId != undefined ? (<></>) : (
                                                <FormControl
                                                      fullWidth
                                                      error={Boolean(touched.password && errors.password)}
                                                      sx={{ ...theme.typography.customInput }}
                                                >
                                                      <InputLabel htmlFor="outlined-adornment-password-register">Password</InputLabel>
                                                      <OutlinedInput
                                                            id="outlined-adornment-password-register"
                                                            type={showPassword ? 'text' : 'password'}
                                                            value={values.password}
                                                            name="password"
                                                            label="Password"
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                  handleChange(e);
                                                                  changePassword(e.target.value);
                                                            }}
                                                            endAdornment={
                                                                  <InputAdornment position="end">
                                                                        <IconButton
                                                                              aria-label="toggle password visibility"
                                                                              onClick={handleClickShowPassword}
                                                                              onMouseDown={handleMouseDownPassword}
                                                                              edge="end"
                                                                              size="large"
                                                                        >
                                                                              {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                        </IconButton>
                                                                  </InputAdornment>
                                                            }
                                                            inputProps={{}}
                                                      />
                                                      {touched.password && errors.password && (
                                                            <FormHelperText error id="standard-weight-helper-text-password-register">
                                                                  {errors.password}
                                                            </FormHelperText>
                                                      )}
                                                </FormControl>
                                          )}

                                          {strength !== 0 && (
                                                <FormControl fullWidth>
                                                      <Box sx={{ mb: 2 }}>
                                                            <Grid container spacing={2} alignItems="center">
                                                                  <Grid item>
                                                                        <Box
                                                                              style={{ backgroundColor: level?.color }}
                                                                              sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                                                        />
                                                                  </Grid>
                                                                  <Grid item>
                                                                        <Typography variant="subtitle1" fontSize="0.75rem">
                                                                              {level?.label}
                                                                        </Typography>
                                                                  </Grid>
                                                            </Grid>
                                                      </Box>
                                                </FormControl>
                                          )}

                                          <Grid container alignItems="center" justifyContent="space-between">
                                                <Grid item>
                                                      <FormControlLabel
                                                            control={
                                                                  <Checkbox
                                                                        checked={checked}
                                                                        onChange={(event) => setChecked(event.target.checked)}
                                                                        name="checked"
                                                                        color="primary"
                                                                  />
                                                            }
                                                            label={
                                                                  <Typography variant="subtitle1">
                                                                        D'accord avec &nbsp;
                                                                        <Typography variant="subtitle1" component={Link} to="#">
                                                                              Termes et conditions.
                                                                        </Typography>
                                                                  </Typography>
                                                            }
                                                      />
                                                </Grid>
                                          </Grid>
                                          {errors.submit && (
                                                <Box sx={{ mt: 3 }}>
                                                      <FormHelperText error>{errors.submit}</FormHelperText>
                                                </Box>
                                          )}

                                          <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                      <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="secondary"
                                                      >
                                                            Modifier
                                                      </Button>
                                                </AnimateButton>
                                          </Box>
                                    </form>
                              )}
                        </Formik>
                  )}
            </MainCard>

      );
};

export default ProfilePage;
