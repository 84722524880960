// material-ui

import { Alert, Avatar, Box, Button, ButtonGroup, Grid, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
// project imports
import ServerService from 'services/server.service';
import { useState, useEffect } from 'react';
import CustmizeTabs from 'ui-component/Tabs/CustmizeTabs';
import BackUpService from 'services/BackUpDb.service';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import ListIcon from '@mui/icons-material/List';
import DynamiqueMenuButton from 'ui-component/Tabs/DynamiqueMenuButton';
import DialogBackups from 'ui-component/Modal/DialogBackups';
import { CoPresentOutlined, Label } from '@mui/icons-material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import RestoreDbService from 'services/RestoreDbs.service';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-github';
import CreateTableForm from './CreateTableForm';
import AlterTableForm from './AlterTableForm';


const FormRequst = () => {
    const [sqlCmmd, setSqlCmmd] = useState("");
    const [valueTbas, setValueTbas] = useState("");
    const [results, setResults] = useState([]);
    const [treeCheckedDatabase, setTreeCheckedDatabase] = useState([]);
    const [loading, setLoading] = useState(false);
    const [htmlSetter, setHtmlSetter] = useState((<></>));
    const handleSubmit = (event) => {

        event.preventDefault();
        setResults([]);
        let treeChecked = localStorage.getItem('treeChecked');
        if (treeChecked) {
            treeChecked = JSON.parse(localStorage.getItem('treeChecked'));
            setTreeCheckedDatabase(treeChecked);
        }

        ServerService.ExcuteRequstSql(treeChecked, sqlCmmd).then(
            (ee) => { setResults(ee); },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        );
    }
    const getbackup = async (curr) => {
        event.preventDefault();
        setLoading(true);
        await BackUpService.ExcuteRequstSQlBackUp(curr).then(
            (ee) => { setLoading(false); },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        );
    }
    const DownloadHistoriqueBackupDatabase = async (value) => {

        // setHtmlSetter((<></>));
        await BackUpService.DownloadBackUpById(value).then(
            (ee) => {},
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        )
    }
    const getHistoriqueBackupDatabase = async (curr) => {
        event.preventDefault();
        setLoading(true);
        var arrIds = curr.split("$&-&$");
        await BackUpService.getBackUpBYServerIdAndDatabase(arrIds[0], arrIds[1]).then(
            (ee) => {
                const data = ee.map((obj, idx) => {
                    const date = new Date(obj.createdAt);
                    const formattedDate = date.toLocaleString('fr-FR', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                        timeZone: 'Africa/Tunis'
                    }).replace(/,/g, '');
                    const action = (
                        <Button
                            onClick={() => DownloadHistoriqueBackupDatabase(obj.id)}
                            key={'ListItemButton' + obj.id}
                            variant="contained">
                            <DownloadForOfflineIcon />
                        </Button>
                    );
                    return {
                        label: obj.nameDataBase + ' ' + formattedDate,
                        id: obj.id,
                        action: action
                    }
                });
                console.log(data);
                setLoading(false);
                let openDialogue = true;
                setHtmlSetter(
                    (
                        <DialogBackups
                            key={curr}
                            keyOption={curr}
                            selectedValue={''}
                            open={openDialogue}
                            options={data}
                            actionAdd={(
                                <>
                                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                        <Button onClick={async () => {
                                            await getbackup(curr);
                                            await setHtmlSetter((<></>));
                                            await getHistoriqueBackupDatabase(curr);
                                            }} 
                                            variant="contained">
                                            {'Ajouer ' + arrIds[1] + ' Sauvgarde'}
                                        </Button>
                                    </ButtonGroup>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() =>  { setHtmlSetter((<></>));}}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <DisabledByDefaultIcon />
                                    </IconButton>
                                </>

                            )}
                            nameoflist={arrIds[1] + ' Sauvgarde'}

                        />
                    )
                );
            },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        );
    }
    const ClieckedRestoreDatabase = async (value) => {

        // setHtmlSetter((<></>));
        await RestoreDbService.ExcuteRequstSQlgetRestoreDb(value).then(
            (ee) => {},
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        )
    }
    const getRestoreDatabase = async (curr) => {
        event.preventDefault();
        setLoading(true);
        var arrIds = curr.split("$&-&$");
        await BackUpService.getBackUpBYServerIdAndDatabase(arrIds[0], arrIds[1]).then(
            (ee) => {
                const data = ee.map((obj, idx) => {
                    const date = new Date(obj.createdAt);
                    const formattedDate = date.toLocaleString('fr-FR', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                        timeZone: 'Africa/Tunis'
                    }).replace(/,/g, '');
                    const action = (
                        <Button
                            onClick={() => ClieckedRestoreDatabase(obj.id)}
                            key={'ListItemButton' + obj.id}
                            variant="contained">
                            <RestoreFromTrashIcon />
                        </Button>
                    );
                    return {
                        label: obj.nameDataBase + ' ' + formattedDate,
                        id: obj.id,
                        action: action
                    }
                });
                console.log(data);
                setLoading(false);
                let openDialogue = true;
                setHtmlSetter(
                    (
                        <DialogBackups
                            key={curr}
                            keyOption={curr}
                            selectedValue={''}
                            open={openDialogue}
                            options={data}
                            actionAdd={(
                                <>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() =>  { setHtmlSetter((<></>));}}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <DisabledByDefaultIcon />
                                    </IconButton>
                                </>

                            )}
                            nameoflist={arrIds[1] + ' Sauvgarde'}

                        />
                    )
                );
            },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        );
    }

    const handleChangeTabs = (event, newValue) => {
        setValueTbas(newValue);
      };
    
    useEffect(() => {
        let treeChecked = localStorage.getItem('treeChecked');
        if (treeChecked) {
            treeChecked = JSON.parse(localStorage.getItem('treeChecked'));
            setTreeCheckedDatabase(treeChecked);
        }
    }, []);

    return (<Grid container key={"FormRequetteSql"}>
    <Grid item xs={12}>
            
    <List dense={true}>
        {treeCheckedDatabase.map((curr, idx) => {
            var arrIds = curr.split("$&-&$");
            return (

                <ListItem
                key={"AlerteDataBase" + idx}
                secondaryAction={
                    <DynamiqueMenuButton
                            options={
                                [
                                    {
                                        label: 'Base de données Sauvegardé',
                                        func: async (e) => await getbackup(curr)
                                    },
                                    {
                                        // label :<DialogBackups key={'History'+idx} keyoption={'KeyOptionHistory'+idx} btnname={'L\'historiques des Base de données'} /> ,
                                        label: 'L\'historiques des Base de données',
                                        func: async (e) => await getHistoriqueBackupDatabase(curr)
                                        
                                    },
                                    {
                                        label: 'Restauration des Base de données',
                                        func: async (e) => await getRestoreDatabase(curr)
                                    }
                                ]
                            }
                        />
                        }
                    >
                <ListItemText
                primary={arrIds[1]}
                />
            </ListItem>
                    

            );
        })}
    </List>
</Grid>
<Grid item xs={12}>
    <Grid container spacing={2} mb={2} >
        <Grid item xs={12}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={valueTbas}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                        <Tab label="Create Table" value="0" />
                        <Tab label="Alter Table" value="1" />
                        <Tab label="X" value="" />
                    </TabList>
                    </Box>
                    <TabPanel value="0">
                        <CreateTableForm setSqlCmmd={setSqlCmmd} sqlCmmd={sqlCmmd}  />
                    </TabPanel>
                    <TabPanel value="1">
                        <AlterTableForm setSqlCmmd={setSqlCmmd} sqlCmmd={sqlCmmd}  />
                    </TabPanel>
                </TabContext>
            </Box>
            
        </Grid>
        {/* <Grid item xs={6}>
            <AlterTableForm setSqlCmmd={setSqlCmmd} sqlCmmd={sqlCmmd}  />
        </Grid> */}
    </Grid>
    
    {/* <AlterTableForm  /> */}
    <form onSubmit={handleSubmit}>

            Enter SQL code:
            <AceEditor
            mode="sql"
            theme="github"
            value={sqlCmmd}
            onChange={(value) => {
                setSqlCmmd(value)
            }}
            name="sql-editor"
            editorProps={{ $blockScrolling: true }}
            style={{ width:'100%',height: '400px' }}
            />
            
        <Button variant="contained" type='submit' endIcon={<SendIcon />}>
            Envoyer
        </Button>
    </form>
</Grid>
<Grid item xs={12}>
    <CustmizeTabs
        tabsContent={
            results.map((curr, idx) => {
                return curr;
            })}
    />
</Grid>
{htmlSetter}
    </Grid>

       
)

}
export default FormRequst;
