import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import ListIcon from '@mui/icons-material/List';



const ITEM_HEIGHT = 100;

export default function DynamiqueMenuButton(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const options = props.options;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <LoadingButton
          size="small"
          color="secondary"
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          loadingPosition="start"
          startIcon={<ListIcon />}
      ></LoadingButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {options.map((option,idx) => (
          <MenuItem key={idx} 
            onClick={option.func}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}