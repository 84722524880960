// material-ui
import { Checkbox, FormControl, Grid, FormControlLabel, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
// project imports
import { Formik } from 'formik';
import { Box } from '@mui/system';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import OneServers from 'services/oneServers.service';
import ServerDataBasesService from 'services/ServerDataBases.service';
// ==============================|| SAMPLE PAGE ||============================== //

const FormLabelDatabaseServer = (props) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [initData, setInitData] = useState(props.getDataInfo);


    const handleSubmit = async (values, { setErrors, setStatus, setSubmitting }) =>{

        ServerDataBasesService.CreateOrUpdate(props.id,props.ServerId,values.nameDatabase,values.nameDatabseSpecified,values.description).then(
            (succ)=>{
                props.onClose();
                // props.setDialogFullWith(mddl);
            },
            (err)=>{console.log(err);}
            );
    }

    useEffect(() => {
        if(props.id!=null && props.ServerId!=null){
            setInitData(props.getDataInfo);
        }
    },[initData]);
    return (
        <Formik 
                initialValues={initData}
                validationSchema={Yup.object().shape({
                    nameDatabase: Yup.string().max(255).required('Nom Base de donnée is required'),
                    nameDatabseSpecified: Yup.string().max(255).required('Nom Client  is required'),
                    description: Yup.string().max(255).required('Description Base de donnée is required')
                })}
                onSubmit={handleSubmit}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} >
                        <Grid container spacing={matchDownSM ? 0 : 2} mt={2}>
                            <FormControl fullWidth error={Boolean(touched.nameDatabase && errors.nameDatabase)} >
                                <InputLabel htmlFor="outlined-nameDatabase">Nom Base de donnée</InputLabel>
                                <OutlinedInput
                                    id="outlined-nameDatabase"
                                    type="text"
                                    value={values.nameDatabase}
                                    name="nameDatabase"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Nom Base de donnée"
                                    inputProps={{}}
                                />
                                {touched.nameDatabase && errors.nameDatabase && (
                                    <FormHelperText error id="standard-weight-helper-text-nameDatabase">
                                        {errors.nameDatabase}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth error={Boolean(touched.nameDatabseSpecified && errors.nameDatabseSpecified)} >
                                <InputLabel htmlFor="outlined-nameDatabseSpecified">Nom Client</InputLabel>
                                <OutlinedInput
                                    id="outlined-nameDatabseSpecified"
                                    type="text"
                                    value={values.nameDatabseSpecified}
                                    name="nameDatabseSpecified"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Nom Client"
                                    inputProps={{}}
                                />
                                {touched.nameDatabseSpecified && errors.nameDatabseSpecified && (
                                    <FormHelperText error id="standard-weight-helper-text-nameDatabseSpecified">
                                        {errors.nameDatabseSpecified}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth error={Boolean(touched.description && errors.description)} >
                                <InputLabel htmlFor="outlined-description">Description</InputLabel>
                                <OutlinedInput
                                    id="outlined-description"
                                    type="text"
                                    value={values.description}
                                    name="description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Description"
                                    inputProps={{}}
                                />
                                {touched.description && errors.description && (
                                    <FormHelperText error id="standard-weight-helper-text-description">
                                        {errors.description}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}
                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    Submit
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
                    
        </Formik>
        
        
    );
  };

export default FormLabelDatabaseServer;
