// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';
import config from 'config';


// constant
const icons = { IconBrandChrome, IconHelp };
// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
let permission =config.decodeToken.role?.includes("Admin");
console.log(config.decodeToken);
const account = {
    id: 'account_Profile_Utilisateurs_Roles_pages',
    title: 'Account',
    caption: 'Account',
    type: 'group',
    children: [
        {
            id: 'account_Profile',
            title: 'Profile',
            type: 'collapse',
            icon: icons.Icon123,
            children: [
                {
                    id: 'account_Profile',
                    title: 'Profile',
                    type: 'item',
                    url: '/account/Profile',
                    external: false,
                    target: false,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'account_Utilisateurs',
            title: 'Utilisateurs',
            type: 'collapse',
            icon: icons.Icon123,
            showing: permission,
            children: [
                {
                    id: 'account_Utilisateurs',
                    title: 'Utilisateurs',
                    type: 'item',
                    url: '/account/Utilisateurs',
                    external: false,
                    target: false,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'account_Roles',
            title: 'Roles',
            type: 'collapse',
            icon: icons.Icon123,
            showing: permission,
            children: [
                {
                    id: 'account_Roles',
                    title: 'Roles',
                    type: 'item',
                    url: '/account/Roles',
                    external: false,
                    target: false,
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default account;
