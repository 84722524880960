import React from 'react';
import Row from './Row';
import { useState } from 'react';
import { useEffect } from 'react';
export default function Rows(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.rows);
      }, [props.isLoading,data]);

      
    return (
        <React.Fragment  >
            { data.map((row) => (<Row key={'Row'+row.id +'$&-&$'+row.name}  row={row} />))}
        </React.Fragment>
    );
    
  }