import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import config from 'config';
import { useEffect, useState } from 'react';

import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { LoadingButton } from '@mui/lab';
import LogsServersService from 'services/logsServers.service';
import MainCard from 'ui-component/cards/MainCard';
import React from 'react';
import { format } from 'prettier';
import moment from 'moment';
import { gridSpacing } from 'store/constant';
import TotalIncomeLightCard from '../Default/TotalIncomeLightCard';
import TotalIncomeDarkCard from '../Default/TotalIncomeDarkCard';
import TotalOrderLineChartCard from '../Default/TotalOrderLineChartCard';
import EarningCard from '../Default/EarningCard';
import PopularCard from '../Default/PopularCard';
import NombreServer from './NombreServer';
import TotalGrowthBarChart from '../Default/TotalGrowthBarChart';
import NombreDatabase from './NombreDatabase';
import NombreWorkerService from './NombreWorkerService';
import NombreDayWorkerService from './NombreDayWorkerService';
import PanelLogs from './PanelLogs';
import PanelLogsService from './PanelLogsService';
import ChartWorkerService from './ChartWorkerService';
import DashbordService from 'services/dashbord.service';
import { useQuery, useQueryClient, QueryClientProvider, QueryClient } from 'react-query';
// ==============================|| DEFAULT DASHBOARD ||============================== //

const API_URL = config.domaineName + "/api/";
const fetchingdataWidgert = async () => {
    const res = await fetch(API_URL+"Dashbord/Widgets");
    const jsonData = await res.json();
    console.log(jsonData);
    return jsonData;
  };
const fetchingdataCarts = async () => {
    const res = await fetch(API_URL+"Dashbord/Charts");
    const jsonData = await res.json();
    console.log(jsonData);
    return jsonData;
  };

const DashboardSuperViseur = () => {
    // const [isLoading, setLoading] = useState(true);
    // const [data, setData] = useState([]);
    const [dataWidgets, setDataWidgets] = useState({});
    const [dataWidgetsStatus, setDataWidgetsStatus] = useState(true);
    const [htmlSetterRows, setHtmlSetterRows] = useState((<></>));

    const { data:dataWidget, status:statusWidget, isLoading:isLoadingWidget } = useQuery("dashbordwidget", fetchingdataWidgert);
    const { data:dataCharts, status:statusCharts, isLoading:isLoadingCharts } = useQuery("dashbordcharts", fetchingdataCarts);



    return (
        
        <Grid container spacing={gridSpacing}>
            
            {/* {status === "error" && <p>Error fetching data</p>}
      {status === "loading" && <p>Fetching data...</p>} */}
      
        <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                {statusWidget === "success" && (<>
                        <Grid item xl={2} lg={3} md={6} sm={6} xs={12}>
                            <NombreServer NbCount={dataWidget.nbServer} isLoading={isLoadingWidget} />
                        </Grid>
                        <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                            <NombreDatabase NbCount={dataWidget.nbDatabase} isLoading={isLoadingWidget} />
                        </Grid>
                        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                            <NombreWorkerService 
                                NbCount={ (dataWidget.nbSqlCmd + dataWidget.nbUrlInvocation + dataWidget.nbScriptShell) }
                             isLoading={isLoadingWidget} />
                        </Grid>
                        <Grid item xl={4} lg={3} md={6} sm={12} xs={12}>
                            <NombreDayWorkerService 
                            NbCount={ (dataWidget.nbSqlCmdExecuterToday + dataWidget.nbUrlInvocationExecuterToday + dataWidget.nbScriptShellExecuterToday ) }
                             isLoading={isLoadingWidget} />
                        </Grid>
                </>)}
                        
                {statusCharts === "success" && (<>
                    <Grid item md={12} sm={12} xs={12}>
                        <ChartWorkerService dataCharts={dataCharts}  statusCharts={statusCharts} isLoading={isLoadingCharts} />
                    </Grid>
                </>)}
                    
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid style={{display:"none"}} item xs={12} md={12}>
                        <PanelLogsService isLoading={false}  />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <PanelLogs isLoading={false} />
                    </Grid>
                    
                </Grid>
            </Grid>
      
      

            
                 
        </Grid>

    );
};

export default DashboardSuperViseur;
