import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ServersPage from 'views/pages/servers-page';
import RolesPage from 'views/pages/Roles';
import UtilisateurPage from 'views/pages/Utilisateurs';
import ProfilePage from 'views/pages/Profile';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    children: [
        {
            path: '/account',
            text:"Account",
            element: (<MainLayout idurlSideBar={"account"}   custmSideBar={false} showingSideBar={true}  />),
            children: [
                {
                    path: '',
                    element: <ProfilePage />
                },
                {
                    path: 'Profile',
                    element: <ProfilePage />
                },
                {
                    path: 'Utilisateurs',
                    element: <UtilisateurPage />
                },
                {
                    path: 'Roles',
                    element: <RolesPage />
                }
            ]
        }
    ]
};

export default MainRoutes;
