// material-ui
import { Checkbox, FormControl, Grid, FormControlLabel, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
// project imports
import { Formik } from 'formik';
import { Box } from '@mui/system';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import OneServers from 'services/oneServers.service';
// ==============================|| SAMPLE PAGE ||============================== //

const FormAddDatabaseServer = (props) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [initData, setInitData] = useState(props.getDataInfo);


    const handleSubmit = async (values, { setErrors, setStatus, setSubmitting }) =>{
        OneServers.AddDataBases(values.id,values.nameDatabase).then(
            (succ)=>{
                let mddl = {
                    openClose: false,
                    component: <></>
                };
                props.onClose();
                // props.setDialogFullWith(mddl);
            },
            (err)=>{console.log(err);}
            );
    }

    useEffect(() => {
        
        if(props.id!=null){
            setInitData(props.getDataInfo);
        }
    },[initData]);
    return (
        <Formik 
                initialValues={initData}
                validationSchema={Yup.object().shape({
                    nameDatabase: Yup.string().max(255).required('Nom Base de donnée is required')
                })}
                onSubmit={handleSubmit}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} >
                        <Grid container spacing={matchDownSM ? 0 : 2} mt={2}>
                            <FormControl fullWidth error={Boolean(touched.nameDatabase && errors.nameDatabase)} >
                                <InputLabel htmlFor="outlined-nameDatabase">Nom Base de donnée</InputLabel>
                                <OutlinedInput
                                    id="outlined-nameDatabase"
                                    type="text"
                                    value={values.nameDatabase}
                                    name="nameDatabase"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Nom Base de donnée"
                                    inputProps={{}}
                                />
                                {touched.nameDatabase && errors.nameDatabase && (
                                    <FormHelperText error id="standard-weight-helper-text-nameDatabase">
                                        {errors.nameDatabase}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}
                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    Submit
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
                    
        </Formik>
        
        
    );
  };

export default FormAddDatabaseServer;
