import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import config from 'config';
import { useEffect, useState } from 'react';

import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { LoadingButton } from '@mui/lab';
import LogsServersService from 'services/logsServers.service';
import MainCard from 'ui-component/cards/MainCard';
import React from 'react';
import moment from 'moment';
import SkeletonPopularCard from 'ui-component/cards/Skeleton/PopularCard';
// ==============================|| DEFAULT DASHBOARD ||============================== //

const PanelLogsService = ({ isLoading }) => {
    const [data, setData] = useState([]);
    const [htmlSetterRows, setHtmlSetterRows] = useState((<></>));

    const BtnActualiser = async () => {
        setHtmlSetterRows(<></>);
        await fetchData();
    }

    async function fetchData() {
        LogsServersService.gets().then(
            (ee) => {
                let logs = [];
                ee.map((item) => {
                    var datetCreated = Date.parse(item.createdAt.toString());
                    var dateToString = moment(datetCreated).format("YYYY/MM/DD kk:mm:ss");
                    logs.push(
                        <React.Fragment key={item.id} >
                            <TableRow style={{ paddingBottom: 0, paddingTop: 0 }} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} component="th" scope="row">
                                    {dateToString}
                                </TableCell>
                                <TableCell style={{ width: '100px', paddingBottom: 0, paddingTop: 0 }} component="th" scope="row">
                                    {item.result}
                                </TableCell>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} component="th" scope="row" align="left">
                                    {item.status}
                                </TableCell>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} component="th" scope="row" align="left">
                                    {item.failed ?
                                        <Button variant="outlined" size="small" color="error" startIcon={<SmsFailedIcon fontSize="inherit" />}>
                                            Failed
                                        </Button>
                                        :
                                        <Button variant="outlined" size="small" color="success" startIcon={<CheckBoxIcon fontSize="inherit" />}>
                                            Successed
                                        </Button>}
                                </TableCell>
                            </TableRow>
                        </React.Fragment>

                    );
                });
                setData(logs);

                setHtmlSetterRows(<React.Fragment >
                    {logs.map((itm) => {
                        return (itm);
                    })}
                </React.Fragment>);

            },
            (error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
            }
        );

    }
    useEffect(() => {
        BtnActualiser();
    }, []);

    return (
        <MainCard>
            {isLoading ? (
                <SkeletonPopularCard />
            ) : (
                <TableContainer component={Paper}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <Typography variant="h3">
                                            <LoadingButton
                                                size="small"
                                                onClick={(e) => BtnActualiser()}
                                                loading={isLoading}
                                                loadingPosition="start"
                                                startIcon={<></>}
                                                variant="contained"
                                            >
                                                <span>Actualiser</span>
                                            </LoadingButton>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2">Derniers services </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>



                    <Table size="small" aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell style={{ width: '100px', paddingBottom: 0, paddingTop: 0 }}>Resultat</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell align="center">Etat</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading == false ? (htmlSetterRows) : (<></>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </MainCard>
    );
};

export default PanelLogsService;
